import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { DrawerLeft } from "./components/DrawerLeft";
import { AppBarTop } from "./AppBarTop";

interface DashboardTemplateProps {
   children: any;
   title?: string;
}

export default (props: DashboardTemplateProps) => {
   const { children, title = "Parec Inc" } = props;
   const [open, setOpen] = React.useState(true);
   const toggleDrawer = () => {
      setOpen(!open);
   };

   return (
      <>
         <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBarTop />

            <DrawerLeft
               variant='permanent'
               open={open}
               toggleDrawer={toggleDrawer}
            />
            <Box
               component='main'
               sx={{
                  backgroundColor: (theme) =>
                     theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                  flexGrow: 1,
                  height: "100vh",
                  overflow: "auto",
               }}
            >
               <Toolbar />
               <Container maxWidth='lg' sx={{ mt: 1, mb: 4 }}>
                  <h3>{title}</h3>
                  {children}
               </Container>
            </Box>
         </Box>
      </>
   );
};
