import React from "react";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import BarChart from "./BarChart";
import { Listing, ListingsState } from "../../../features/listings/listings";
import { useAppSelector } from "../../../app/hooks";
import {
   countDistinct,
   countDistinctLarge,
   propertiesInPricePoint,
} from "./utilities/chartGeneral";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export default function (): JSX.Element {
   let tmp: Listing[] = useAppSelector((state: any) => state.listings.arr);
   const [field, setField] = React.useState("subdivision");
   const chartData =
      field === "price_current"
         ? propertiesInPricePoint(tmp)
         : countDistinctLarge(tmp, field, 10);

   const handleChange = (event: any) => {
      setField(event.target.value as string);
   };

   return (
      <>
         <DashboardTemplate title={"Charts Home"}>
            <FormControl fullWidth>
               <InputLabel id='demo-simple-select-label'>
                  Graph by field
               </InputLabel>
               <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={field}
                  label='Graph by field'
                  onChange={(e) => handleChange(e)}
               >
                  <MenuItem value='subdivision'>Subdivision</MenuItem>
                  <MenuItem value='listing_office_id'>Listing Office</MenuItem>
                  <MenuItem value='price_current'>Price</MenuItem>
                  <MenuItem value='listing_agent_id'>Listing Agent</MenuItem>
                  <MenuItem value='status'>Status</MenuItem>
               </Select>
            </FormControl>
            <br />
            <br />
            <BarChart arr={chartData} field={""} />
         </DashboardTemplate>
      </>
   );
}
