import React, { useState } from "react";
import { Listing } from "../../../features/listings/listings";
import { Image } from "../../../features/images/images";
import {
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TablePagination,
   TableRow,
   TextField,
} from "@mui/material";
import { ImageBox } from "./ImageBox";
import { useAppSelector } from "../../../app/hooks";

function findOrphanedImages(listings: Listing[], images: Image[], setSQL: any) {
   const orphanedImages: Image[] = [];

   // Extract uniq_ids from listings
   const uniqIds = listings.map((listing) => listing.unique_id.slice(4));

   // Iterate over images
   images.forEach((image) => {
      // Extract uniq_id from imageName
      const matchResult = image.imageName.substring(4, 8); // Get 4 characters starting from index 4
      if (matchResult !== null) {
         const uniqIdFromImageName = matchResult; // Extracting 4 digits
         // Check if uniq_id exists in the uniqIds array
         if (!uniqIds.includes(uniqIdFromImageName)) {
            orphanedImages.push(image);
         }
      }
   });

   const orphanedUniqueIdsSet = new Set<string>();

   // Iterate over orphaned images and add unique_ids to the set
   orphanedImages.forEach((image) => {
      const uniqueId = image.imageName.substring(4, 8);
      orphanedUniqueIdsSet.add(uniqueId);
   });

   // Convert the set to an array
   const orphanedUniqueIds = Array.from(orphanedUniqueIdsSet);
   console.log(orphanedUniqueIds.length);
   const smallOrphanedUniqueIds = orphanedUniqueIds.slice(3000, 3400);

   // Construct SQL statement to update orphaned field to 1 for orphaned unique_ids
   const sqlUpdateOrphaned = `
   UPDATE images
   SET orphaned = 1
   WHERE imageName LIKE ${smallOrphanedUniqueIds
      .map((id) => `'%${id}%'`)
      .join(" OR imageName LIKE ")}
`;

   console.log(sqlUpdateOrphaned);

   console.log("Orphaned Unique IDs Count:", orphanedImages.length);
   console.log(
      "Non-Orphaned Unique IDs Count:",
      listings.length - orphanedImages.length
   );

   return orphanedImages;
}

export default function TabOrphaned(): JSX.Element {
   const listings: Listing[] = useAppSelector(
      (state: any) => state.listings.arr
   );
   const [sql, setSQL] = useState("");
   const images: Image[] = useAppSelector((state: any) => state.images.arr);

   let rows: Image[] = findOrphanedImages(listings, images, setSQL);

   // Pagination state
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);
   const [imgHeight, setImgHeight] = useState(100);

   // Search filter state
   const [searchTerm, setSearchTerm] = useState("");
   //    const [imageName, setImageName] = useState<any>("");

   // Change handler for pagination
   const handleChangePage = (event: any, newPage: any) => {
      event.preventDefault();
      setPage(newPage);
   };

   // Change handler for rows per page
   const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };
   const handleChangeSearch = (event: any) => {
      setSearchTerm(event.target.value);
      setPage(0);
   };

   const filteredImages = rows.filter((image: any) =>
      image.imageName.toLowerCase().includes(searchTerm.toLowerCase())
   );

   return (
      <>
         <TextField
            id='outlined-multiline-static'
            label='SQL'
            multiline
            rows={4}
            fullWidth
            defaultValue={sql}
         />
         <br />
         <br />
         {/* Search input */}
         <TextField
            size='medium'
            label='Search Orphaned Images'
            variant='outlined'
            value={searchTerm}
            onChange={(e) => handleChangeSearch(e)}
            style={{ marginBottom: "1rem" }}
         />
         <FormControl style={{ width: 150, marginLeft: 10 }}>
            <InputLabel id='demo-simple-select-label'>Image Height</InputLabel>
            <Select
               labelId='demo-simple-select-label'
               id='demo-simple-select'
               value={imgHeight}
               label='Image Height'
               onChange={(e) => setImgHeight(Number(e.target.value))}
            >
               <MenuItem value={50}>50px</MenuItem>
               <MenuItem value={100}>100px</MenuItem>
               <MenuItem value={200}>200px</MenuItem>
               <MenuItem value={300}>300px</MenuItem>
            </Select>
         </FormControl>
         {/* Table container */}
         <TableContainer>
            <Table>
               <TableBody>
                  {/* Render images for the current page */}
                  {filteredImages
                     .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                     )
                     .map((row: any) => (
                        <TableRow key={"i" + row.imageName}>
                           <TableCell>
                              <ImageBox row={row} imgHeight={imgHeight} />
                           </TableCell>
                        </TableRow>
                     ))}
               </TableBody>
            </Table>
         </TableContainer>
         {/* Pagination controls */}
         <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component='div'
            count={filteredImages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </>
   );
}
