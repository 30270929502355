import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { DialogState } from "../../features/dialog/dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearDialog } from "../../features/dialog/dialogSlice";
import DialogEditOne from "./EditOne";
import DialogViewOne from "./ViewOne";
import NoneSpecified from "./NoneSpecified";
import DialogAddOne from "./AddOne";
import DialogShowImages from "./ShowImages";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & {
      children: React.ReactElement<any, any>;
   },
   ref: React.Ref<unknown>
) {
   return <Slide direction='up' ref={ref} {...props} />;
});

export default React.memo((): JSX.Element => {
   const dispatch = useAppDispatch();
   const dialog: DialogState = useAppSelector((state) => state.dialog);
   const { open, content, params } = dialog;

   const handleClose = () => {
      dispatch(clearDialog());
   };

   const compPicker = (content: string, params: any): any => {
      switch (content) {
         case "View":
            return <DialogViewOne params={params} />;
         case "Edit":
            return <DialogEditOne params={params} />;
         case "Add":
            return <DialogAddOne params={params} />;
         case "images":
            return <DialogShowImages params={params} />;

         default:
            return <NoneSpecified />;
      }
   };

   return (
      <>
         <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth='xl'
         >
            {compPicker(content, params)}
         </Dialog>
      </>
   );
});
