import React, { Fragment } from "react";
import { useSnackbar } from "notistack";
import { useAppSelector } from "../../app/hooks";
import { SnackBarType } from "../../features/snackbar/snackbar";

const Messages = () => {
   const snackbar: SnackBarType = useAppSelector((state) => state.snackbar);
   const { enqueueSnackbar } = useSnackbar();

   const [prevMsg, setPrevMsg] = React.useState("");

   React.useEffect(() => {
      if (snackbar.msg !== prevMsg) {
         enqueueSnackbar(snackbar.msg, { variant: snackbar.severity });
         setPrevMsg(snackbar.msg);
      }
   }, [snackbar.msg, prevMsg, enqueueSnackbar, snackbar.severity]);

   return <></>;
};

export default Messages;
