export const cities = [
   { name: "Panama City", lat: 8.9824, long: -79.5199 },
   //    { name: "San Miguelito", lat: 9.0333, long: -79.5 },
   { name: "Bocas del Toro", lat: 9.3333, long: -82.25 },
   { name: "David", lat: 8.4333, long: -82.4333 },
   { name: "Colón", lat: 9.3592, long: -79.9014 },
   { name: "La Chorrera", lat: 8.8803, long: -79.7833 },
   //    { name: "Tocumen", lat: 9.0833, long: -79.3833 },
   { name: "Santiago", lat: 8.1, long: -80.9833 },
   { name: "Chitré", lat: 7.9667, long: -80.4333 },
   { name: "Boquete", lat: 8.7833, long: -82.4333 },
   { name: "Las Tablas", lat: 7.7667, long: -80.2833 },
   { name: "Coronado", lat: 8.545047, long: -79.91209 },
   { name: "Penonomé", lat: 8.532, long: -80.35 },
   { name: "Punta Chame", lat: 8.645, long: -79.706 },
   { name: "Playa Blanca", lat: 8.343745, long: -80.156 },
   { name: "Pedasí", lat: 7.5333, long: -80.0333 },
   { name: "Boca Chica", lat: 8.2076, long: -82.194 },
   { name: "Playa Venao", lat: 7.431, long: -80.193 },
   { name: "Santa Catalina", lat: 7.7667, long: -81.2333 },
   { name: "Portobelo", lat: 9.55, long: -79.65 },
   { name: "El Valle", lat: 8.596, long: -80.127 },
   //    { name: "Sorá", lat: 8.634, long: -80.011 },
];
