import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import agentsReducer from "../features/agents/agentsSlice";
import companiesReducer from "../features/companies/companiesSlice";
import dialogReducer from "../features/dialog/dialogSlice";
import menuReducer from "../features/menu/menuSlice";
import pageReducer from "../features/page/pageSlice";
import sessionReducer from "../features/session/sessionSlice";
import snackbarReducer from "../features/snackbar/snackbarSlice";
import suggestReducer from "../features/suggest/suggestSlice";
import linksReducer from "../features/links/linksSlice";
import listingsReducer from "../features/listings/listingsSlice";
import officesReducer from "../features/offices/officesSlice";
import imagesReducer from "../features/images/imageSlice";
import adminsReducer from "../features/admins/adminsSlice";

export const store = configureStore({
   reducer: {
      admins: adminsReducer,
      agents: agentsReducer,
      dialog: dialogReducer,
      menu: menuReducer,
      page: pageReducer,
      session: sessionReducer,
      snackbar: snackbarReducer,
      suggest: suggestReducer,
      companies: companiesReducer,
      links: linksReducer,
      listings: listingsReducer,
      offices: officesReducer,
      images: imagesReducer,
   },
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: false,
      }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
   ReturnType,
   RootState,
   unknown,
   Action<string>
>;
