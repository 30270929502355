import React from "react";
import { Listing } from "../../../../features/listings/listings";
import { IMG_PATH } from "../../../../constants/api";
import { useAppDispatch } from "../../../../app/hooks";
import { setDialog } from "../../../../features/dialog/dialogSlice";
import Button from "@mui/material/Button";

interface MarkerPopupProps {
   listing: Listing;
}

export default function MarkerPopup(props: MarkerPopupProps) {
   const { listing } = props;
   const dispatch = useAppDispatch();

   const handleView = () => {
      dispatch(
         setDialog({
            open: true,
            title: `View Listing`,
            content: "View",
            params: {
               row: listing,
               tableName: "listings",
               imgColName: "images",
               imgWidth: "160px",
               imgHeight: "100px",
            },
         })
      );
   };

   let address = "";

   if (listing.street_number !== undefined)
      address += `${listing.street_number} `;
   if (listing.street_name !== undefined) address += listing.street_name;
   if (listing.subdivision !== undefined) address += `, ${listing.subdivision}`;
   if (listing.region !== undefined) address += `, ${listing.region}`;

   let image = "/assets/img/noImage.png";
   if (
      listing.images !== undefined &&
      listing.images !== null &&
      listing.images.includes(",")
   ) {
      const images = listing.images.split(",");
      image = IMG_PATH + "/" + images[0];
   }
   const specs = `Rooms:${listing.bedrooms || 0} Bathroom:${
      listing.bathrooms || 0
   } Sqft:${listing.sqft_total || 0}`;

   const buyPrice =
      listing.price_current &&
      listing.price_current > listing.price_current_rent
         ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
           }).format(Number(listing.price_current))
         : "NA";
   const rentPrice = listing.price_current_rent
      ? new Intl.NumberFormat("en-US", {
           style: "currency",
           currency: "USD",
        }).format(Number(listing.price_current_rent))
      : "NA";

   return (
      <>
         <div>
            <strong>
               Buy:{buyPrice} - Rent:{rentPrice}
            </strong>
         </div>
         <div>{specs}</div>
         <div>{address}</div>
         <div>
            <img src={image} alt='tn' width='100%' height='180' />
         </div>
         <div>
            <Button variant='outlined' fullWidth onClick={() => handleView()}>
               View Listing
            </Button>
         </div>
      </>
   );
}
