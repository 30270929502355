import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import * as am5 from "@amcharts/amcharts5";
import am5geodata_data_countries2 from "@amcharts/amcharts5-geodata/data/countries2";
import { useEffect } from "react";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { mapCoords } from "./utilities/mapChartConstants";
import {
   Box,
   CircularProgress,
   FormControl,
   InputLabel,
   Menu,
   MenuItem,
   Select,
} from "@mui/material";
import { moveMapToGeo } from "./utilities/mapMoveToGeo";
import React from "react";
import { cities } from "../../../constants/data/cities";
import { IMG_PATH } from "../../../constants/api";

export default function MapChartPoints() {
   const listings: any = useAppSelector(
      (state: RootState) => state.listings.arr
   );
   const images: any = useAppSelector((state: RootState) => state.images.arr);
   // const imagesNotOrphaned = images.filter(
   //    (image: any) => image.orphaned === false
   // );
   const uniqueRegions: string[] = Array.from(
      new Set(listings.map((item: any) => item.region))
   );
   const [region, setRegion] = React.useState<any>("All");
   const pointSeries = React.useRef<any>(null);
   const citySeries = React.useRef<any>(null);

   const handleChangeRegion = (event: any) => {
      setRegion(event.target.value as string);
   };

   useEffect(() => {
      //am5.ready(function() {
      let root = am5.Root.new("chartDivPoints");

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
         am5map.MapChart.new(root, {
            panX: "rotateX",
            projection: am5map.geoMercator(),
            layout: root.horizontalLayout,
         })
      );

      let zoomControl = chart.set(
         "zoomControl",
         am5map.ZoomControl.new(root, {
            layout: root.verticalLayout,
            paddingRight: 30,
            paddingBottom: 30,
            opacity: 0.5,
         })
      );
      zoomControl.homeButton.set("visible", true);

      loadGeodata("PA");

      // Create polygon series for continents
      // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
      let polygonSeries = chart.series.push(
         am5map.MapPolygonSeries.new(root, {
            calculateAggregates: true,
            valueField: "value",
            sequencedInterpolation: true,
            sequencedDelay: 2000,
         })
      );

      // start point series for properties
      pointSeries.current = chart.series.push(
         am5map.MapPointSeries.new(root, {})
      );
      pointSeries.current.bullets.push(function () {
         let circle = am5.Circle.new(root, {
            radius: 4,
            tooltipY: 0,
            fill: am5.color(0xffba00),
            stroke: root.interfaceColors.get("background"),
            strokeWidth: 2,
            tooltipHTML:
               '<div style="max-width: 300px; white-space: normal; overflow-wrap: break-word;">{title}<br />{address}<br /><img src="{image}" alt="tn" width="250" height="180" /></div>',
         });

         return am5.Bullet.new(root, {
            sprite: circle,
         });
      });

      // point series for major cities
      // Create a new point series for the cities array
      citySeries.current = chart.series.push(
         am5map.MapPointSeries.new(root, {})
      );
      citySeries.current.bullets.push(function () {
         let container = am5.Container.new(root, {});
         let circle = container.children.push(
            am5.Circle.new(root, {
               radius: 4,
               opacity: 0.8,
               fill: am5.color(0xff0000), // Use red color for the fill
               stroke: root.interfaceColors.get("background"),
               strokeWidth: 0,
            })
         );
         let cityLabel = container.children.push(
            am5.Label.new(root, {
               text: "{title}",
               paddingLeft: 8,
               populateText: true,
               fontWeight: "bold",
               fontSize: 12,
               centerY: am5.p50,
            })
         );
         circle.on("radius", function (radius) {
            cityLabel.set("x", radius);
         });

         return am5.Bullet.new(root, {
            sprite: container,
            dynamic: true,
         });
      });

      chart.zoomIn();
      // chart.events.on("wheel", function (event) {

      setTimeout(() => chart.zoomOut(), 3200);
      //chart.appear(8000, 500); // duration, delay

      function loadGeodata(country: any) {
         country == "US"
            ? chart.set("projection", am5map.geoAlbersUsa())
            : chart.set("projection", am5map.geoMercator());

         // calculate which map to be used
         let title = "",
            currentMap;
         if (am5geodata_data_countries2[country] !== undefined) {
            currentMap = am5geodata_data_countries2[country]["maps"][0];
            console.log(currentMap);

            // add country title
            if (am5geodata_data_countries2[country]["country"]) {
               title = am5geodata_data_countries2[country]["country"];
            }
         }

         am5.net
            .load("/assets/json/panamaHigh.json", chart)
            .then(function (result: any) {
               let geodata = am5.JSONParser.parse(result.response);
               let data = mapCoords;
               data.forEach((item) => (item.value = 0));

               polygonSeries.set("geoJSON", geodata);
               polygonSeries.data.setAll(data);
            });

         chart.seriesContainer.children.push(
            am5.Label.new(root, {
               x: 5,
               y: 5,
               text: title,
               background: am5.RoundedRectangle.new(root, {
                  fill: am5.color(0xffffff),
                  fillOpacity: 0.2,
               }),
            })
         );
      }

      cities.forEach((item: any) => {
         addCity(
            item.long as number,
            item.lat as number,
            item.name as string,
            "Point"
         );
      });

      listings.forEach((item: any) => {
         if (region === "All" || region === item.region) {
            let address = "";
            //const unique_id = item.unique_id.slice(4, 8);

            if (item.street_number !== undefined)
               address += `${item.street_number} `;
            if (item.street_name !== undefined) address += item.street_name;
            if (item.city !== undefined) address += `<br />${item.city}`;
            if (item.region !== undefined) address += `<br />${item.region}`;

            // find the first image that accures to the listing
            // const imageName = images.find((image: any) =>
            //    image.imageName.includes(unique_id)
            // );

            let image = "/assets/img/noImage.png";
            if (
               item.images !== undefined &&
               item.images !== null &&
               item.images.includes(",")
            ) {
               const images = item.images.split(",");
               image = IMG_PATH + "/" + images[0];
            }

            addProperty(
               item.longitude as number,
               item.latitude as number,
               new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
               }).format(Number(item.price_current || 0)),
               image,
               address
            );
         }
      });

      return () => {
         // Cleanup code, such as disposing the chart or root, if needed
         chart.dispose();
         root.dispose();
      };
      //})
   });
   ///////

   function addProperty(
      longitude: number,
      latitude: number,
      title: string,
      image: string,
      address: string
   ) {
      pointSeries.current.data.push({
         geometry: { type: "Point", coordinates: [longitude, latitude] },
         title,
         image,
         address,
      });
   }

   function addCity(
      longitude: number,
      latitude: number,
      title: string,
      type = "Point"
   ) {
      citySeries.current.data.push({
         geometry: { type, coordinates: [longitude, latitude] },
         title: title,
      });
   }

   return (
      <>
         <DashboardTemplate title={"Map Chart Points"}>
            <FormControl fullWidth>
               <InputLabel id='region-label'>Region</InputLabel>
               <Select
                  labelId='region-label'
                  id='region'
                  value={region}
                  label='region'
                  onChange={(e) => handleChangeRegion(e)}
               >
                  <MenuItem value='All'>All</MenuItem>
                  {uniqueRegions.map((region: string, index: number) => (
                     <MenuItem key={index} value={region}>
                        {region}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>

            <div
               id='chartDivPoints'
               style={{
                  marginTop: "20px",
                  width: "100%",
                  height: "600px",
                  backgroundColor: "white",
                  position: "relative", // Ensure relative positioning for the parent div
                  zIndex: 0, // Lower z-index to ensure it's behind the absolute div
               }}
            ></div>
         </DashboardTemplate>
      </>
   );
}
