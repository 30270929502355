import {
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   Grid,
   Slider,
   Typography,
} from "@mui/material";
import { useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import { formatCurrency } from "../../../../utilities/gen";

interface SelectorsProps {
   handleChangeRegion: (e: any) => void;
   handleChangeProperty: (e: any) => void;
   handleMapHeight: (change: string) => void;
   handleRangeChangePrice: (e: any, newValue: number | number[]) => void;
   handleChangeRangeSize: (e: any, newValue: number | number[]) => void;
   priceRange: number[];
   sizeRange: number[];
   region: string;
   property: string;
}

const Selectors = (props: SelectorsProps) => {
   const {
      handleChangeRegion,
      handleChangeProperty,
      handleMapHeight,
      handleChangeRangeSize,
      handleRangeChangePrice,
      priceRange,
      sizeRange,
      region,
      property,
   } = props;

   const listings: any = useAppSelector(
      (state: RootState) => state.listings.arr
   );

   const uniqueRegions: string[] = Array.from(
      new Set(listings.map((item: any) => item.region))
   );
   const uniqPropertyTypes: string[] = Array.from(
      new Set(listings.map((item: any) => item.property_type))
   );
   const marginRight = 10;
   const width = 100;

   return (
      <div
         style={{
            display: "flex",
            flexWrap: "wrap",
            marginBottom: 10,
            width: "100%",
         }}
      >
         <Grid container spacing={5}>
            <Grid item xs={4}>
               <FormControl style={{ marginRight, width }}>
                  <InputLabel id='region1-label'>Region</InputLabel>
                  <Select
                     labelId='region1-label'
                     variant='standard'
                     id='region1'
                     fullWidth={true}
                     value={region}
                     onChange={(e) => handleChangeRegion(e)}
                  >
                     <MenuItem value='All'>All</MenuItem>
                     {uniqueRegions.map((region, index) => (
                        <MenuItem key={index} value={region}>
                           {region}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>

               <FormControl style={{ marginRight, width }}>
                  <InputLabel id='type-label'>Type</InputLabel>
                  <Select
                     labelId='type-label'
                     variant='standard'
                     id='type'
                     fullWidth={true}
                     value={property}
                     onChange={(e) => handleChangeProperty(e)}
                  >
                     <MenuItem value='All'>All</MenuItem>
                     {uniqPropertyTypes.map((prop, index) => (
                        <MenuItem key={index} value={prop}>
                           {prop}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Grid>
            <Grid item xs={4}>
               <Slider
                  value={priceRange}
                  onChange={handleRangeChangePrice}
                  valueLabelDisplay='auto'
                  aria-labelledby='range-slider'
                  step={500} // Increment by 500
                  max={1200000}
                  min={0}
                  valueLabelFormat={(value) => formatCurrency(value)}
               />
               <Typography variant='body2'>
                  Price Range - Min: {formatCurrency(priceRange[0])} - Max:
                  {formatCurrency(priceRange[1])}
                  {priceRange[1] === 1200000 ? "(and over)" : ""}
               </Typography>
            </Grid>
            <Grid item xs={4} container>
               <Slider
                  value={sizeRange}
                  onChange={handleChangeRangeSize}
                  valueLabelDisplay='auto'
                  aria-labelledby='range-slider'
                  step={50} // Increment by 500
                  max={500}
                  min={0}
                  valueLabelFormat={(value) => value}
               />
               <Typography variant='body2'>
                  Area(sqft) Min: {sizeRange[0]} - Max:
                  {sizeRange[1]}
                  {sizeRange[1] === 500 ? "(and over)" : ""}
               </Typography>
            </Grid>
         </Grid>
      </div>
   );
};

export default Selectors;
