import * as React from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AdminsState } from "../../../features/admins/admins";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";

import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";

// set this up based on the object types in AdminsState

export default function GridAdmins() {
   const dispatch = useAppDispatch();
   const admins: AdminsState = useAppSelector((state: any) => state.admins);
   const rows = admins.arr;

   const handleDelete = (id: number) => {
      // Handle delete action
   };

   const handleView = (id: number) => {
      const viewRow = admins.arr.filter((a) => parseInt(a.id) === id);
      dispatch(
         setDialog({
            open: true,
            title: `View Admin`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "admins",
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "Actions",
         width: 60,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               {/* <IconButton
                  aria-label='edit'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.id)}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton> */}
               <IconButton
                  aria-label='view'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
               {/* <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleDelete(params.row.id)}
               >
                  <DeleteIcon fontSize='inherit' />
               </IconButton> */}
            </>
         ),
      },
      { field: "id", headerName: "ID", width: 70 },
      { field: "name", headerName: "Name", width: 120 },
      {
         field: "email",
         headerName: "Email",
         width: 200,
      },
      { field: "createdAt", headerName: "Created", width: 120 },
      {
         field: "updatedAt",
         headerName: "Updated",
         width: 180,
      },
   ];

   React.useEffect(() => {
      // reload if admins state changes
   }, [admins.arr]);

   return (
      <DashboardTemplate title={"Admins Grid View"}>
         <DataGrid
            density='compact'
            rows={rows}
            columns={columns}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
         />
      </DashboardTemplate>
   );
}
