import React, { useEffect } from "react";
import L, { LatLngExpression } from "leaflet";
import { icon, IconOptions } from "leaflet";
import { Marker, Popup } from "react-leaflet";
import PlaceIcon from "@mui/icons-material/Place";
import { Listing } from "../../../../features/listings/listings";
import MarkerPopup from "./MarkerPopup";
interface MarkerIconImageProps {
   listing: Listing;
}

const MarkerIconColor = (props: MarkerIconImageProps) => {
   const { listing } = props;
   const { property_type, latitude, longitude } = listing;
   const position: LatLngExpression = [
      parseFloat(latitude),
      parseFloat(longitude),
   ];

   let iconUrl = "/assets/img/icons/markerRed.png";

   switch (property_type) {
      case "Apartamento":
         iconUrl = "/assets/img/icons/markerBlue.png";
         break;
      case "Casa":
         iconUrl = "/assets/img/icons/markerGreen.png";
         break;
      case "Terreno":
         iconUrl = "/assets/img/icons/markerYellow.png";
         break;
      case "Finca":
         iconUrl = "/assets/img/icons/markerPurple.png";
         break;
   }

   const markerIcon = L.icon({
      iconUrl,
      iconSize: [15, 20],
      iconAnchor: [7, 20],
      popupAnchor: [1, -34],
   });

   return (
      <Marker position={position} icon={markerIcon}>
         <Popup>
            <MarkerPopup listing={listing} />
         </Popup>
      </Marker>
   );
};

export default MarkerIconColor;
