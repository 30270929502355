import React from "react";
import Box from "@mui/material/Box";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppSelector } from "../../../app/hooks";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { ImagesState } from "../../../features/images/images";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { IMG_PATH } from "../../../constants/api";

const formatDate = (dateString: number | string) => {
   const date = new Date(dateString);
   return date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "short",
      day: "2-digit",
   });
};

export default function ImagesGrid() {
   let images: ImagesState = useAppSelector((state: any) => state.images);

   const rows = images.arr;
   const handleDelete = (id: number) => {
      // Handle delete action
   };
   const handleView = (img: string) => {
      // open image in new tab
      window.open(IMG_PATH + "/" + img, "_blank");
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "Actions",
         width: 80,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.imageName)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleDelete(params.row.id)}
               >
                  <DeleteIcon fontSize='inherit' />
               </IconButton>
            </>
         ),
      },
      {
         field: "imageName",
         headerName: "Image",
         width: 230,
      },

      {
         field: "createdAt",
         headerName: "Created At",
         width: 120,
         valueFormatter: (params: any) => formatDate(params.value),
      },
      { field: "isDeleted", headerName: "Is Deleted", width: 120 },
      { field: "orphaned", headerName: "orphaned", width: 120 },
   ];

   React.useEffect(() => {
      // reload if images state changes
   }, [images.arr]);

   return (
      <div>
         <h2>Images: Grid view</h2>

         <DataGrid
            density='compact'
            rows={rows}
            columns={columns}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
            getRowId={(row) => row.imageName}
         />
      </div>
   );
}
