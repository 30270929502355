import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Logo from "../components/Logo";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import { addSnackbarMsg } from "../features/snackbar/snackbarSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { isValidEmail, isValidPassword } from "../utilities/validate";
import { msg } from "../utilities/gen";
import { apiPost } from "../utilities/ApiRequest";
import { setSession } from "../features/session/sessionSlice";
import { SnackbarState } from "../features/snackbar/snackbar";
import { API_URL } from "../constants/api";

import { SessionState } from "../features/session/session";
import { useEffect } from "react";
import SnackStack from "../components/Snackbar/SnackStack";

interface SignInFormElement extends HTMLFormElement {
   readonly elements: any;
}

function ColorSchemeToggle(props: IconButtonProps) {
   const session: SessionState = useAppSelector((state: any) => state.session);
   const dispatch = useAppDispatch();
   const { onClick, ...other } = props;
   const { mode, setMode } = useColorScheme();
   const [mounted, setMounted] = React.useState(false);

   React.useEffect(() => {
      setMounted(true);
   }, []);
   if (!mounted) {
      return (
         <IconButton size='sm' variant='outlined' color='neutral' disabled />
      );
   }
   return (
      <IconButton
         id='toggle-mode'
         size='sm'
         variant='outlined'
         color='neutral'
         aria-label='toggle light/dark mode'
         {...other}
         onClick={(event) => {
            dispatch(setSession({ ...session, darkMode: !session.darkMode }));
            if (mode === "light") {
               setMode("dark");
            } else {
               setMode("light");
            }
            onClick?.(event);
         }}
      >
         {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
   );
}

export default function JoySignInSideTemplate() {
   const navigate = useNavigate();

   const dispatch = useAppDispatch();
   const session: any = useAppSelector((state) => state.session);
   const snackbar: SnackbarState = useAppSelector((state) => state.snackbar);
   const [reset, setReset] = React.useState(false);
   const speed = session.speed;

   const handleSubmit = async (data: any) => {
      try {
         const { email, password } = data;

         if (isValidEmail(email) && isValidPassword(password)) {
            dispatch(addSnackbarMsg(msg(`Testing Credentials...`, "info")));
            const res = await apiPost(API_URL + "/agents_login", {
               email,
               password,
            });
            console.log(res);
            setTimeout(() => {
               // allow animation to finish
               if (res.data.err) {
                  dispatch(
                     addSnackbarMsg(msg(`login failed: ${email}`, "error"))
                  );
               } else {
                  const user = {
                     email,
                     token: res.data.token,
                     lastLoginAt: Date.now(),
                     createdAt: 1661467411061,
                  };
                  const agent = res.data.agent;
                  console.log(agent);
                  dispatch(setSession({ ...session, user, agent }));
                  setTimeout(
                     () =>
                        dispatch(
                           addSnackbarMsg(msg(`Login success `, "success"))
                        ),
                     speed + 200
                  );
                  setTimeout(() => navigate(`/dashboard`), speed * 2 + 250);
               }
            }, session.speed * 1000 + 10);
         } else {
            dispatch(
               addSnackbarMsg(
                  msg(`Please enter valid email & password `, "error")
               )
            );
         }
      } catch (error) {
         console.log(error);
         dispatch(addSnackbarMsg(msg(`Error ${new Date()}`, "error")));
      }
   };

   const handleReset = async (email: string) => {
      try {
         dispatch(addSnackbarMsg(msg(`Sending Reset Email...`, "info")));
         const res = await apiPost(API_URL + "/reset_password", {
            email,
         });
         console.log(res);
         setTimeout(() => {
            // allow animation to finish
            if (res.data.err) {
               dispatch(addSnackbarMsg(msg(`Reset failed: ${email}`, "error")));
            } else {
               dispatch(addSnackbarMsg(msg(`Reset success `, "success")));
            }
            setReset(false);
         }, 1000);
      } catch (error) {
         console.log(error);
         dispatch(addSnackbarMsg(msg(`Error ${new Date()}`, "error")));
      }
   };

   useEffect(() => {
      // console.log('snackbar',snackbar)
   }, [snackbar]);

   return (
      <CssVarsProvider defaultMode='dark' disableTransitionOnChange>
         <CssBaseline />
         <GlobalStyles
            styles={{
               ":root": {
                  "--Collapsed-breakpoint": "769px", // form will stretch when viewport is below `769px`
                  "--Cover-width": "50vw", // must be `vw` only
                  "--Form-maxWidth": "800px",
                  "--Transition-duration": "0.4s", // set to `none` to disable transition
               },
            }}
         />
         <Box
            sx={(theme) => ({
               width: "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
               transition: "width var(--Transition-duration)",
               transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
               position: "relative",
               zIndex: 1,
               display: "flex",
               justifyContent: "flex-end",
               backdropFilter: "blur(12px)",
               backgroundColor: "rgba(255 255 255 / 0.2)",
               [theme.getColorSchemeSelector("dark")]: {
                  backgroundColor: "rgba(19 19 24 / 0.4)",
               },
            })}
         >
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100dvh",
                  width: "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
                  maxWidth: "100%",
                  px: 2,
               }}
            >
               <Box
                  component='header'
                  sx={{
                     py: 3,
                     display: "flex",
                     alignItems: "left",
                     justifyContent: "space-between",
                  }}
               >
                  <Box sx={{ gap: 2, display: "flex", alignItems: "center" }}>
                     <Logo />
                  </Box>
                  <ColorSchemeToggle />
               </Box>
               <Box
                  component='main'
                  sx={{
                     "my": "auto",
                     "py": 2,
                     "pb": 5,
                     "display": "flex",
                     "flexDirection": "column",
                     "gap": 2,
                     "width": 400,
                     "maxWidth": "100%",
                     "mx": "auto",
                     "borderRadius": "sm",
                     "& form": {
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                     },
                     [`& .${formLabelClasses.asterisk}`]: {
                        visibility: "hidden",
                     },
                  }}
               >
                  <Stack gap={4} sx={{ mb: 2 }}>
                     <Stack gap={1}>
                        <Typography component='h1' level='h3'>
                           Sign in
                        </Typography>
                     </Stack>
                  </Stack>

                  <Stack gap={4} sx={{ mt: 2 }}>
                     {reset === false ? (
                        <form
                           onSubmit={(
                              event: React.FormEvent<HTMLFormElement>
                           ) => {
                              event.preventDefault();
                              const formElements = event.currentTarget
                                 .elements as any; // Cast to 'any' for easier access to form elements
                              const data = {
                                 email: formElements.email.value,
                                 password: formElements.password.value,
                              };
                              handleSubmit(data);
                           }}
                        >
                           <FormControl required>
                              <FormLabel>Email</FormLabel>
                              <Input type='email' name='email' />
                           </FormControl>
                           <FormControl required>
                              <FormLabel>Password</FormLabel>
                              <Input type='password' name='password' />
                           </FormControl>
                           <Stack gap={4} sx={{ mt: 2 }}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                 }}
                              >
                                 {/* Add your checkbox here */}
                                 <Link
                                    level='title-sm'
                                    href='#replace-with-a-link'
                                    onClick={() => setReset(true)}
                                 >
                                    Forgot your password?
                                 </Link>
                              </Box>
                              <Button type='submit'>Sign in</Button>
                           </Stack>
                        </form>
                     ) : (
                        <>
                           <form
                              onSubmit={(
                                 event: React.FormEvent<HTMLFormElement>
                              ) => {
                                 event.preventDefault();
                                 const formElements = event.currentTarget
                                    .elements as any; // Cast to 'any' for easier access to form elements
                                 const data = {
                                    email: formElements.email.value,
                                 };
                                 handleReset(data.email);
                              }}
                           >
                              {/* Add your forgot password form fields here */}
                              <FormControl required>
                                 <FormLabel>Reset Password Email</FormLabel>
                                 <Input type='email' name='email' />
                              </FormControl>
                              <Button type='submit'>Submit</Button>
                           </form>
                        </>
                     )}
                  </Stack>
               </Box>
               <Box component='footer' sx={{ py: 1 }}>
                  <Typography level='body-xs' textAlign='center'>
                     © Parec, Inc {new Date().getFullYear()}
                  </Typography>
               </Box>
               <Box component='footer' sx={{ pb: 5 }}>
                  <Typography level='body-xs' textAlign='center'>
                     <span
                        style={{ color: session.darkMode ? "#333" : "#ddd" }}
                     >
                        ver 0.0.42
                     </span>
                  </Typography>
               </Box>
            </Box>
         </Box>
         <Box
            sx={(theme) => ({
               height: "100%",
               position: "fixed",
               right: 0,
               top: 0,
               bottom: 0,
               left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
               transition:
                  "background-image var(--Transition-duration), left var(--Transition-duration) !important",
               transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
               backgroundColor: "background.level1",
               backgroundSize: "cover",
               backgroundPosition: "center",
               backgroundRepeat: "no-repeat",
               backgroundImage:
                  "url(https://images.unsplash.com/photo-1527181152855-fc03fc7949c8?auto=format&w=1000&dpr=2)",
               [theme.getColorSchemeSelector("dark")]: {
                  backgroundImage:
                     "url(https://images.unsplash.com/photo-1572072393749-3ca9c8ea0831?auto=format&w=1000&dpr=2)",
               },
            })}
         />
         <SnackStack />
      </CssVarsProvider>
   );
}
