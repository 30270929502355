import * as React from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ReplayIcon from "@mui/icons-material/Replay";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import Checkbox from "@mui/material/Checkbox";
import { apiPost } from "../../../utilities/ApiRequest";
import { API_URL } from "../../../constants/api";
import {
   Box,
   Button,
   ButtonGroup,
   Divider,
   FormControlLabel,
   FormGroup,
   Grid,
   LinearProgress,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
} from "@mui/material";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { msg } from "../../../utilities/gen";

function formatDate(input: any) {
   // Find the index of the first occurrence of a valid date string (YYYYMMDD)
   const index = input.search(/\d{8}\b/);
   if (index === -1) {
      return "Date not found in the input string";
   }

   // Extract the date part from the input string
   const dateStr = input.substring(index, index + 8);

   // Parse the date string into a Date object
   const year = dateStr.substring(0, 4);
   const month = parseInt(dateStr.substring(4, 6)) - 1; // Month is zero-based in Date object
   const day = dateStr.substring(6, 8);
   const date = new Date(year, month, day);

   // Array of month names
   const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
   ];

   // Format the date in the desired format
   const formattedDate =
      months[date.getMonth()] + "-" + (day < 10 ? "0" + day : day) + "-" + year;

   return formattedDate;
}

export default function DataHome() {
   const dispatch = useAppDispatch();
   const token = useAppSelector((state) => state.session.user.token);
   const [data, setData] = React.useState<any>([]);
   const [csvToo, setCsvToo] = React.useState<any>(true);
   const init = React.useRef<boolean>(false);

   try {
      (async () => {
         try {
            if (!init.current) {
               init.current = true;
               const tmp = await apiPost(
                  API_URL + "/files/readFolderContents",
                  {
                     token,
                  }
               );
               addSnackbarMsg(msg("File Folder loaded", "success"));
               setData(tmp);
            }
         } catch (error) {
            console.error("Error fetching data:", error);
         }
      })();
   } catch (error) {
      console.error("Error:", error);
   }
   const handleDateChange = (date: any) => {
      if (!date) {
         dispatch(addSnackbarMsg(msg("Please select a date.", "error")));
         return; // Exit the function without further processing
      }
      let today: any = new Date();
      today = today.toISOString().slice(0, 10).replace(/-/g, ""); //format YYYYMMDD
      const fileDate = date.toISOString().slice(0, 10).replace(/-/g, "");

      // now compare the selected date with today's date and handle it accordingly
      if (parseInt(fileDate) > parseInt(today)) {
         dispatch(
            addSnackbarMsg(msg("Date is in the future: " + fileDate, "error"))
         );
         return; // Exit the function without further processing
      }
      handleLoadFromFTP(fileDate);
   };

   const handleDelete = async (fileName: string) => {
      const res = await apiPost(API_URL + "/files/removeFile", {
         token,
         fileName,
      });
      addSnackbarMsg(msg("File deleted: " + fileName, "success"));
      handleRefresh();
   };
   const handleClearFolder = async () => {
      const res = await apiPost(API_URL + "/files/removeAllFiles", {
         token,
      });
      addSnackbarMsg(msg("All FTP files removed", "success"));
      handleRefresh();
   };
   const handleDownload = async (fileName: string) => {
      try {
         const response = await fetch(API_URL + "/files/downloadFile", {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify({
               token,
               fileName,
            }),
         });

         if (!response.ok) {
            throw new Error("Failed to download file");
         }

         // Extract filename from the content-disposition header
         const contentDisposition: any = response.headers.get(
            "Content-Disposition"
         );
         const [, extractedFileName] =
            contentDisposition.match(/filename="(.+)"/) || [];
         const downloadFileName = extractedFileName || fileName;

         // Create a blob from the response data
         const blob = await response.blob();

         // Create a temporary URL for the blob
         const url = window.URL.createObjectURL(blob);

         // Create an anchor element
         const a = document.createElement("a");
         a.href = url;
         a.download = downloadFileName; // Set the filename for the downloaded file
         a.click();

         // Release the object URL to free up resources
         window.URL.revokeObjectURL(url);

         dispatch(
            addSnackbarMsg(
               msg("File downloaded: " + downloadFileName, "success")
            )
         );
      } catch (error) {
         console.error("Error downloading file:", error);
      }
   };

   const handleLoadFromFTP = async (fileDate: any) => {
      dispatch(addSnackbarMsg(msg("Gathering files for: " + fileDate, "info")));
      console.log(
         await apiPost(API_URL + "/data/getAll", {
            token,
            csvToo,
            fileDate,
         })
      );
      dispatch(addSnackbarMsg(msg("New files from FTP server", "success")));
      handleRefresh();
   };
   const handleRefresh = async () => {
      init.current = false;
      setData([]);
   };
   const handleLink = async () => {
      addSnackbarMsg(msg("Attempting to link images", "info"));
      const res = await apiPost(API_URL + "/data/linkImages", {
         token,
      });
      addSnackbarMsg(msg("Images linked", "success"));
   };

   const handleRefreshData = async (tableName: string) => {
      try {
         addSnackbarMsg(
            msg("Attempting to refresh data for table: " + tableName, "info")
         );
         const res = await apiPost(API_URL + "/csv/load" + tableName, {
            token,
         });
         addSnackbarMsg(msg("Data refreshed", "success"));
      } catch (error) {
         console.error("Error refreshing data:", error);
         addSnackbarMsg(msg("Error refreshing data: " + error, "error"));
      }
   };

   React.useEffect(() => {
      // reload if agents state changes
   }, [data]);

   return (
      <DashboardTemplate title={"Data Files"}>
         <Grid container spacing={2}>
            <Grid item xs={6}>
               <ButtonGroup>
                  <Button
                     component='label'
                     size='small'
                     variant='text'
                     sx={{ mx: 1, my: 3 }}
                     onClick={() => handleRefresh()}
                     startIcon={<ReplayIcon />}
                  >
                     Refresh files
                  </Button>
                  <Button
                     component='label'
                     size='small'
                     variant='text'
                     sx={{ mx: 1 }}
                     onClick={() =>
                        handleLoadFromFTP(
                           new Date()
                              .toISOString()
                              .slice(0, 10)
                              .replace(/-/g, "")
                        )
                     }
                     startIcon={<CloudDownloadIcon />}
                  >
                     Load Newest
                  </Button>
                  <Button
                     component='label'
                     size='small'
                     variant='text'
                     sx={{ mx: 1 }}
                     onClick={() => handleClearFolder()}
                     startIcon={<CloudDownloadIcon />}
                  >
                     Clear Folder
                  </Button>
                  <Button
                     component='label'
                     size='small'
                     variant='text'
                     sx={{ mx: 1, my: 3 }}
                     onClick={() => handleLink()}
                     startIcon={<ReplayIcon />}
                  >
                     Link Images
                  </Button>
               </ButtonGroup>
            </Grid>
            <Grid item xs={6}>
               <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                     <DatePicker
                        label='Load Files by Date'
                        onChange={(newValue) => handleDateChange(newValue)}
                     />
                  </DemoContainer>
               </LocalizationProvider>
               <FormGroup>
                  <FormControlLabel
                     control={
                        <Checkbox
                           defaultChecked
                           onChange={() => setCsvToo(!csvToo)}
                        />
                     }
                     label='Include CSV'
                  />
               </FormGroup>
            </Grid>
         </Grid>
         <Divider style={{ margin: 20 }} />

         <div style={{ width: "100%" }}>
            <div
               style={{ display: "block", width: "100%", textAlign: "center" }}
            >
               <div
                  style={{
                     display: "inline-block",
                     margin: "0 auto",
                     textAlign: "left",
                  }}
               >
                  Refresh Data From files
                  <br />
                  <ButtonGroup
                     variant='outlined'
                     aria-label='Basic button group'
                  >
                     <Button onClick={() => handleRefreshData("Agents")}>
                        Agents
                     </Button>
                     <Button onClick={() => handleRefreshData("Companies")}>
                        Companies
                     </Button>
                     <Button onClick={() => handleRefreshData("Links")}>
                        Links
                     </Button>
                     <Button onClick={() => handleRefreshData("Listings")}>
                        Listings
                     </Button>
                     <Button onClick={() => handleRefreshData("Offices")}>
                        Offices
                     </Button>
                  </ButtonGroup>
               </div>
            </div>
         </div>
         <Divider style={{ margin: 20 }} />
         {!data || !data.data || !data.data.files ? (
            // Loading indicator
            <Box sx={{ width: "100%", py: 1 }}>
               Loading Files
               <LinearProgress />
            </Box>
         ) : (
            // Table with data
            <Table size='small'>
               <TableHead>
                  <TableRow>
                     <TableCell>Actions</TableCell>
                     <TableCell>File Name</TableCell>
                     <TableCell>File Type</TableCell>
                     <TableCell>File Date</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {/* Remove extra whitespace between tags */}
                  {data.data.files &&
                     data.data.files
                        .filter(
                           (file: any) =>
                              !file.startsWith(".") && /\d/.test(file)
                        )
                        .map((file: any) => (
                           <TableRow key={file}>
                              <TableCell>{/* Action buttons */}</TableCell>
                              <TableCell>{file}</TableCell>
                              <TableCell>{/* File type */}</TableCell>
                              <TableCell>{formatDate(file)}</TableCell>
                           </TableRow>
                        ))}
               </TableBody>
            </Table>
         )}
      </DashboardTemplate>
   );
}
