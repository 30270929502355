import { Card, CardMedia, Grid } from "@mui/material";
import { IMG_PATH } from "../../../constants/api";

interface ThumbnailProps {
   image: string;
   imgHeight: number;
   imgWidth?: number;
}

export const Thumbnail = (props: ThumbnailProps) => {
   const { image, imgHeight = 180, imgWidth = 270 } = props;
   const handleCardClick = (imageName: string) => {
      // Open the image in a new window
      window.open(image, "_blank");
   };
   return (
      <img
         src={image}
         alt='My Image'
         onClick={() => handleCardClick(image)}
         style={{
            cursor: "pointer",
            borderRadius: "5px",
            height: imgHeight,
            width: imgWidth,
         }}
      />
   );
};
