import Box from "@mui/material/Box";
import React, { useRef } from "react";
import { useAppSelector } from "../../app/hooks";
import { ImagesState } from "../../features/images/images";
import { Thumbnail } from "../../pages/Protected/Listings/Thumbnail";
import { IMG_PATH } from "../../constants/api";

interface ImageProps {
   params: {
      images: string;
   };
}

export default function DialogShowImages(props: ImageProps): JSX.Element {
   let { images } = props.params;
   console.log(images);
   images += ",";
   const imagesArr = images.split(",");
   //const images: ImagesState = useAppSelector((state: any) => state.images);

   return (
      <div
         style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "5px",
         }}
      >
         {imagesArr.map((image, index) => {
            return (
               <div key={index} style={{ padding: "5px" }}>
                  <Thumbnail image={IMG_PATH + "/" + image} imgHeight={180} />
               </div>
            );
         })}
      </div>
   );
}
