import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ImagesState } from "./images";

const initialState: ImagesState = {
   arr: [],
   init: false,
};

export const imagesSlice = createSlice({
   name: "images",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setImages: (state, action: PayloadAction<ImagesState>) => {
         // Clear the state before setting new images
         state.arr.splice(0, state.arr.length);
         // Push each new image into the state
         action.payload.arr.forEach((image) => {
            state.arr.push(image);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleImage: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the image with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((image: any) => image.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleImage: (state, action: PayloadAction<ImagesState>) => {
         // Add a single image
         state.arr.push(action.payload.arr[0]);
      },
      clearImages: (state) => {
         // Clear the state
         state.arr.splice(0, state.arr.length);
         state.init = false;
      },
   },
});

export const { setImages, clearImages, updateSingleImage, addSingleImage } =
   imagesSlice.actions;
export const selectImages = (state: RootState) => state.images;

export default imagesSlice.reducer;
