import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Listing, ListingsState } from "./listings";

const initialState: ListingsState = {
   arr: [],
   init: false,
};

const convertStringFieldsToNumbers = (listing: Listing): Listing => {
   const transformedListing: any = { ...listing }; // Create a copy of the original listing object

   // Iterate over each field of the listing object
   for (const key in transformedListing) {
      if (Object.prototype.hasOwnProperty.call(transformedListing, key)) {
         // Check if the field value is a string and can be converted to a number
         if (
            typeof transformedListing[key] === "string" &&
            !isNaN(Number(transformedListing[key]))
         ) {
            // Convert the string field to a number
            transformedListing[key] = Number(transformedListing[key]);
         }
      }
   }

   return transformedListing;
};

export const listingsSlice = createSlice({
   name: "listings",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setListings: (state, action: PayloadAction<ListingsState>) => {
         // Clear the state before setting new listings
         state.arr.splice(0, state.arr.length);
         // Push each new listing into the state
         action.payload.arr.forEach((listing) => {
            const transformedListing = convertStringFieldsToNumbers(listing);
            state.arr.push(transformedListing);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleListing: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the listing with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((listing: any) => listing.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleListing: (state, action: PayloadAction<ListingsState>) => {
         // Add a single listing
         state.arr.push(action.payload.arr[0]);
      },
      clearListings: (state) => {
         // Clear the state
         state.arr.splice(0, state.arr.length);
         state.init = false;
      },
   },
});

export const {
   setListings,
   clearListings,
   updateSingleListing,
   addSingleListing,
} = listingsSlice.actions;
export const selectListings = (state: RootState) => state.listings;

export default listingsSlice.reducer;
