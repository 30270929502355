import { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme, lightTheme } from "./components/themes/Theme";
import ProtectedRoute from "./pages/Protected/ProtectedRoute";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";

import { Dashboard } from "./pages/Protected/Dashboard";
import Login from "./pages/Login";
import SignUp from "./pages/Public/Signup";
import Forgot from "./pages/Public/Forgot";
import { SessionState } from "./features/session/session";
import { useAppSelector } from "./app/hooks";
import Terms from "./pages/Public/Legal/Terms";
import Privacy from "./pages/Public/Legal/Privacy";
import AgentsGrid from "./pages/Protected/Agents/Grid";
import CompaniesGrid from "./pages/Protected/Companies/Grid";
import ListingsGrid from "./pages/Protected/Listings/Grid";
import OfficesGrid from "./pages/Protected/Offices/Grid";
import LinksGrid from "./pages/Protected/Links/Grid";
import AgentsHome from "./pages/Protected/Agents/Home";
import ListingsHome from "./pages/Protected/Listings/Home";
import DataHome from "./pages/Protected/Data/Home";
import ImagesHome from "./pages/Protected/Image/ImagesHome";
import ChartsHome from "./pages/Protected/Charts/ChartsHome";
import MapChart from "./pages/Protected/Charts/MapChartRegion";
import MapChartPoints from "./pages/Protected/Charts/MapChartPoints";
import MapComponent from "./pages/Protected/Listings/MapLeaflet";
import GridAdmins from "./pages/Protected/Admins/Grid";

export default function App() {
   // import session from redux store
   const session: SessionState = useAppSelector((state: any) => state.session);

   //const dispatch = useAppDispatch();
   const { darkMode } = session;

   useEffect(() => {
      // update template
   }, [darkMode]);
   return (
      <>
         <Suspense fallback={<div>Loading...</div>}>
            <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
               <CssBaseline />
               <Routes>
                  <Route path='' element={<Login />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/sign-up' element={<SignUp />} />
                  <Route path='/forgot' element={<Forgot />} />
                  <Route path='/privacy' element={<Privacy />} />
                  <Route path='/terms' element={<Terms />} />

                  <Route element={<ProtectedRoute />}>
                     <Route path='/dashboard' element={<Dashboard />} />
                     <Route path='/admins' element={<GridAdmins />} />
                     <Route path='/grid/agents' element={<AgentsGrid />} />
                     <Route
                        path='/grid/companies'
                        element={<CompaniesGrid />}
                     />
                     <Route path='/grid/listings' element={<ListingsGrid />} />
                     <Route path='/grid/offices' element={<OfficesGrid />} />
                     <Route path='/grid/links' element={<LinksGrid />} />

                     <Route path='/home/images' element={<ImagesHome />} />
                     <Route path='/home/agents' element={<AgentsHome />} />
                     <Route path='/home/listings' element={<ListingsHome />} />
                     <Route path='/home/data' element={<DataHome />} />
                     <Route path='/home/charts' element={<ChartsHome />} />
                     <Route path='/map' element={<MapComponent />} />
                     <Route path='/map/view' element={<MapComponent />} />
                     <Route path='/viewMap' element={<MapComponent />} />

                     <Route path='/map/chart/region' element={<MapChart />} />
                     <Route
                        path='/map/chart/points'
                        element={<MapChartPoints />}
                     />
                  </Route>
               </Routes>
            </ThemeProvider>
         </Suspense>
      </>
   );
}
