import Toolbar from "@mui/material/Toolbar";
import ProfileNav from "./components/ProfileNav";
import { useAppSelector } from "../../app/hooks";
import IconLogo from "../Logo/IconLogo";

export const AppBarTop = (): JSX.Element => {
   const session = useAppSelector((state: any) => state.session);
   return (
      <>
         <div
            style={{
               backgroundColor: session.darkMode ? "#222" : "#aaa",
               color: session.darkMode ? "#eee" : "#222",
               boxShadow: "0px 0px 5px 0px #000000",
               zIndex: 10000,
               position: "fixed",
               width: "100%",
               top: 0,
               left: 0,
               right: 0,
               height: "40px",
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
               padding: "0 7px",
               transition: "0.5s",
            }}
         >
            <Toolbar
               variant='dense'
               sx={{
                  pr: "24px", // keep right padding when drawer closed
               }}
            >
               <IconLogo />
               <div style={{ float: "right" }}>
                  <ProfileNav />
               </div>
            </Toolbar>
         </div>
      </>
   );
};
