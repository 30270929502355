import React, { useState } from "react";
import { Image, ImagesState } from "../../../features/images/images";
import { useAppSelector } from "../../../app/hooks";
import {
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TablePagination,
   TableRow,
   TextField,
} from "@mui/material";
import { ImageBox } from "./ImageBox";

export default function ImagesHome(): JSX.Element {
   // const dispatch = useAppDispatch();
   const images: ImagesState = useAppSelector((state: any) => state.images);
   const rows: Image[] = images.arr;

   // Pagination state
   const [page, setPage] = useState<number>(0);
   const [rowsPerPage, setRowsPerPage] = useState<number>(5);
   const [imgHeight, setImgHeight] = useState<number>(100);
   const [searchTerm, setSearchTerm] = useState<string>("");
   const [imgType, setImgType] = useState<string>("all");

   // Change handler for pagination
   const handleChangePage = (event: any, newPage: any) => {
      event.preventDefault();
      setPage(newPage);
   };

   // Change handler for rows per page
   const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };
   const handleChangeSearch = (event: any) => {
      setSearchTerm(event.target.value);
      setPage(0);
   };

   const filteredImages = rows.filter((image: any) => {
      const imageNameLower = image.imageName.toLowerCase();
      const searchTermLower = searchTerm.toLowerCase();

      if (imgType === "all") {
         return imageNameLower.includes(searchTermLower);
      } else if (imgType === "active") {
         return (
            imageNameLower.includes(searchTermLower) && image.orphaned === 0
         );
      } else if (imgType === "orphaned") {
         return (
            imageNameLower.includes(searchTermLower) && image.orphaned === 1
         );
      } else {
         // If imgType is neither 'all', 'active', nor 'orphaned', return false for all images
         return false;
      }
   });

   return (
      <>
         {/* Search input */}
         <TextField
            size='medium'
            label='Search Images'
            variant='outlined'
            value={searchTerm}
            onChange={(e) => handleChangeSearch(e)}
            style={{ marginBottom: "1rem" }}
         />
         <FormControl style={{ width: 150, marginLeft: 10 }}>
            <InputLabel id='demo-simple-select-label'>Image Height</InputLabel>
            <Select
               labelId='demo-simple-select-label'
               id='demo-simple-select'
               value={imgHeight}
               label='Image Height'
               onChange={(e) => setImgHeight(Number(e.target.value))}
            >
               <MenuItem value={50}>50px</MenuItem>
               <MenuItem value={100}>100px</MenuItem>
               <MenuItem value={200}>200px</MenuItem>
               <MenuItem value={300}>300px</MenuItem>
            </Select>
         </FormControl>
         <FormControl style={{ width: 220, marginLeft: 10 }}>
            <InputLabel id='demo-simple-select-label'>Image Type</InputLabel>
            <Select
               value={imgType}
               label='Image Type'
               onChange={(e) => setImgType(e.target.value)}
            >
               <MenuItem value='all'>ALL</MenuItem>
               <MenuItem value='active'>Active Ad Images</MenuItem>
               <MenuItem value='orphaned'>Orphaned</MenuItem>
            </Select>
         </FormControl>
         {/* Table container */}
         <TableContainer>
            <Table>
               <TableBody>
                  {/* Render images for the current page */}
                  {filteredImages
                     .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                     )
                     .map((row: any) => (
                        <TableRow key={"i" + row.imageName}>
                           <TableCell>
                              <ImageBox row={row} imgHeight={imgHeight} />
                           </TableCell>
                        </TableRow>
                     ))}
               </TableBody>
            </Table>
         </TableContainer>
         {/* Pagination controls */}
         <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component='div'
            count={filteredImages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </>
   );
}
