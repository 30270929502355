import {
   Button,
   ButtonGroup,
   Chip,
   Grid,
   Paper,
   Tooltip,
   Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Office, OfficesState } from "../../../features/offices/offices";
import BathroomIcon from "@mui/icons-material/Bathroom";
import BedIcon from "@mui/icons-material/Bed";
import PhotoIcon from "@mui/icons-material/Photo";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ImagesState } from "../../../features/images/images";
import { Thumbnail } from "./Thumbnail";
import { IMG_PATH } from "../../../constants/api";
import { setDialog } from "../../../features/dialog/dialogSlice";

export const ListingBox = (listing: any) => {
   const dispatch = useAppDispatch();
   let ls = listing.row;
   const offcies: OfficesState = useAppSelector((state: any) => state.offices);
   const images: ImagesState = useAppSelector((state: any) => state.images);
   const office = offcies.arr.find(
      (office: Office) => office.id === ls.office_id
   );
   const price = ls.price_current
      ? new Intl.NumberFormat("en-US", {
           style: "currency",
           currency: "USD",
        }).format(Number(ls.price_current))
      : "";

   const handleMapClick = (lat: number, lng: number) => {
      // Open a new window with Google Maps
      const newWindow: any = window.open(
         `https://www.google.com/maps?q=${lat},${lng}`,
         "_blank"
      );
      if (newWindow) {
         // Add a marker to the map
         newWindow.addEventListener("load", () => {
            const marker = newWindow.google.maps.Marker({
               position: { lat: lng },
               map: newWindow.map,
               title: "Marker",
            });
         });
      } else {
         // Handle popup blocker
         alert("Please allow popups to see the map.");
      }
   };

   const handleImages = (images: string, uniq_id: number) => {
      dispatch(
         setDialog({
            open: true,
            title: "Images for listing " + uniq_id,
            content: "images",
            params: { images },
         })
      );
      // Handle edit action
   };

   let image = "/assets/img/noImage.png";
   try {
      if (
         ls.images !== undefined &&
         ls.images !== null &&
         ls.images.includes(",")
      ) {
         const images = ls.images.split(",");
         image = IMG_PATH + "/" + images[0];
      }
      if (
         ls &&
         (!ls.unique_id ||
            ls.unique_id === "" ||
            ls.unique_id === undefined ||
            ls.unique_id === null)
      ) {
         ls.unique_id = "000000NA";
      }
   } catch (error) {
      console.log("Error in ListingBox.tsx: ", error);
   }
   return (
      <Paper
         sx={{
            p: 2,
            margin: "auto",
            flexGrow: 1,
            backgroundColor: (theme) =>
               theme.palette.mode === "dark" ? "#1A2027" : "#fff",
         }}
      >
         <Grid container spacing={2}>
            <Grid item>
               <Thumbnail image={image} imgHeight={180} />
            </Grid>
            <Grid item xs={12} sm container>
               <Grid item xs container direction='column' spacing={2}>
                  <Grid item xs>
                     <Typography
                        gutterBottom
                        variant='subtitle1'
                        component='div'
                     >
                        <Chip label={price} size='small' color='success' />{" "}
                        {ls.map_area}({ls.year_built})
                        <ButtonGroup sx={{ px: 1 }}>
                           <Tooltip title='Photos' arrow>
                              <Button
                                 variant='outlined'
                                 startIcon={<PhotoIcon />}
                                 size='small'
                                 onClick={() =>
                                    handleImages(
                                       ls.images,
                                       ls.unique_id.toString().slice(-4)
                                    )
                                 }
                              >
                                 {ls.listing_photo_count}
                              </Button>
                           </Tooltip>
                           <Tooltip title='Bathrooms' arrow>
                              <Button
                                 variant='outlined'
                                 startIcon={<BathroomIcon />}
                                 size='small'
                              >
                                 {ls.bathrooms}
                              </Button>
                           </Tooltip>
                           <Tooltip title='Bedrooms' arrow>
                              <Button
                                 variant='outlined'
                                 startIcon={<BedIcon />}
                                 size='small'
                              >
                                 {ls.bedrooms}
                              </Button>
                           </Tooltip>
                           <Tooltip title='Square feet total' arrow>
                              <Button variant='outlined' size='small'>
                                 {ls.sqft_total}ft<sup>2</sup>
                              </Button>
                           </Tooltip>
                           <Tooltip title='Lot Sq/ft' arrow>
                              <Button variant='outlined' size='small'>
                                 {ls.lot_sqft} ft<sup>2</sup>
                              </Button>
                           </Tooltip>
                        </ButtonGroup>
                        <Tooltip title='Location'>
                           <Button
                              style={{ marginTop: -10 }}
                              color='secondary'
                              size='small'
                              onClick={() =>
                                 handleMapClick(ls.latitude, ls.longitude)
                              }
                           >
                              <LocationOnIcon />
                           </Button>
                        </Tooltip>
                     </Typography>
                     <Typography
                        variant='body2'
                        gutterBottom
                        component={"span"}
                     >
                        {ls.street_number === 0 ? null : ls.street_number}{" "}
                        {ls.street_name} {ls.district}{" "}
                     </Typography>
                     <Typography
                        variant='body2'
                        color='text.secondary'
                        component={"span"}
                     >
                        {ls.directions}
                        <div
                           style={{
                              width: "100%",
                              height: "90px",
                              overflowY: "scroll",
                           }}
                        >
                           {ls.remarks}
                        </div>
                     </Typography>
                  </Grid>
                  <Grid item>
                     <Typography
                        sx={{ cursor: "pointer" }}
                        variant='body2'
                        component={"span"}
                     >
                        {ls.property_name}{" "}
                        <Chip label={ls.date_listed} size='small' />
                        <Chip
                           label={
                              ls.unique_id
                                 ? ls.unique_id.toString().slice(-4)
                                 : "0000NA"
                           }
                           color='primary'
                           size='small'
                        />
                        <Button
                           size='small'
                           variant='outlined'
                           style={{ marginLeft: 10 }}
                           onClick={() =>
                              handleImages(
                                 ls.images,
                                 ls.unique_id.toString().slice(-4)
                              )
                           }
                        >
                           Show all images
                        </Button>
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </Paper>
   );
};
