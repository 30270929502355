// boilerplate for tsx component

import React from "react";

const logoStyle = {
   width: "130px",
   height: "30px",
   margin: "auto",
   display: "block",
   borderRadius: 10,
   padding: 4,
   backgroundColor: "white",
};

export default function () {
   return (
      <>
         <img src='/assets/img/Logo-sm.png' style={logoStyle} />
      </>
   );
}
