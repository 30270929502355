import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabHome from "./TabImagesAll";
import TabGrid from "./TabImagesGrid";
import TabOrphaned from "./TabOrphaned";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function CustomTabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography component={"span"} variant={"body2"}>
                  {children}
               </Typography>
            </Box>
         )}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      "id": `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

export default function ImagesHome() {
   const [value, setValue] = React.useState(0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <DashboardTemplate title={"Inspect Images"}>
         <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
               <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label='basic tabs example'
               >
                  <Tab label='View All' {...a11yProps(0)} />
                  <Tab label='Grid All' {...a11yProps(1)} />
                  <Tab label='View Orphaned' {...a11yProps(2)} />
               </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
               <TabHome />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
               <TabGrid />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
               <TabOrphaned />
            </CustomTabPanel>
         </Box>
      </DashboardTemplate>
   );
}
