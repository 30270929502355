import React, { useState } from "react";
import { AgentsState } from "../../../features/agents/agents";
import { useAppSelector } from "../../../app/hooks";
import {
   Button,
   Chip,
   Grid,
   Paper,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TablePagination,
   TableRow,
   TextField,
} from "@mui/material";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import { Office, OfficesState } from "../../../features/offices/offices";
import { formatDisplayUrl, formatUrl } from "../../../utilities/gen";

const AgentsBox = (agent: any) => {
   const offcies: OfficesState = useAppSelector((state: any) => state.offices);
   const office = offcies.arr.find(
      (office: Office) => office.id === agent.row.office_id
   );

   return (
      <Grid container spacing={1} alignItems='center'>
         <Grid item xs={4}>
            <Chip label={agent.row.first_name + " " + agent.row.last_name} />
         </Grid>
         <Grid item xs={4}>
            {agent.row.cell ? `Cell: ${agent.row.cell}` : ""}
         </Grid>
         <Grid item xs={4}>
            <Button href={`mailto${agent.row.email}`} size='small'>
               {agent.row.email}
            </Button>
         </Grid>
         <Grid item xs={4}>
            {agent.row.license_number} (office: {agent.row.office_id})
         </Grid>
         <Grid item xs={4}>
            {agent.row.phone ? `Phone: ${agent.row.phone}` : ""}
         </Grid>
         <Grid item xs={4}>
            {agent.row.url ? (
               <Button
                  href={`${formatUrl(agent.row.url)}`}
                  size='small'
                  target='_blank'
               >
                  {formatDisplayUrl(agent.row.url)}
               </Button>
            ) : (
               ""
            )}
         </Grid>
         <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 1 }}>
               {office ? (
                  <>
                     <div>{office.name}</div>
                     <div>{office.phone}</div>
                     <div>{office.address_line_1}</div>
                  </>
               ) : (
                  "No agency linked"
               )}
            </Paper>
         </Grid>
      </Grid>
   );
};

export default function AgentsHome(): JSX.Element {
   // const dispatch = useAppDispatch();
   const agents: AgentsState = useAppSelector((state: any) => state.agents);
   const rows: any = agents.arr;

   // Pagination state
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);

   // Search filter state
   const [searchTerm, setSearchTerm] = useState("");

   // Change handler for pagination
   const handleChangePage = (event: any, newPage: any) => {
      event.preventDefault();
      setPage(newPage);
   };

   // Change handler for rows per page
   const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };
   const handleChangeSearch = (event: any) => {
      setSearchTerm(event.target.value);
      setPage(0);
   };

   // Filter agents by first_name and last_name
   const filteredAgents = rows.filter(
      (agent: any) =>
         agent.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
         agent.last_name.toLowerCase().includes(searchTerm.toLowerCase())
   );

   React.useEffect(() => {
      // reload if agents state changes
   }, [agents.arr]);

   return (
      <DashboardTemplate title={"Agents Home"}>
         {/* Search input */}
         <TextField
            size='small'
            label='Search Names'
            variant='outlined'
            value={searchTerm}
            onChange={(e) => handleChangeSearch(e)}
            style={{ marginBottom: "1rem" }}
         />
         {/* Table container */}
         <TableContainer>
            <Table>
               <TableBody>
                  {/* Render agents for the current page */}
                  {filteredAgents
                     .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                     )
                     .map((row: any) => (
                        <TableRow key={row.id}>
                           <TableCell>
                              <AgentsBox row={row} />
                           </TableCell>
                        </TableRow>
                     ))}
               </TableBody>
            </Table>
         </TableContainer>
         {/* Pagination controls */}
         <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component='div'
            count={filteredAgents.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </DashboardTemplate>
   );
}
