import { useState } from "react";
import { ListingsState } from "../../../features/listings/listings";
import { useAppSelector } from "../../../app/hooks";
import {
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TablePagination,
   TableRow,
   TextField,
} from "@mui/material";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import { ListingBox } from "./ListingBox";

export default function ListingsHome(): JSX.Element {
   // const dispatch = useAppDispatch();
   const listings: ListingsState = useAppSelector(
      (state: any) => state.listings
   );
   const rows: any = listings.arr;

   // Pagination state
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(3);

   // Search filter state
   const [searchTerm, setSearchTerm] = useState("");
   const [subdivision, setSubdivision] = useState<any>("");

   // Change handler for pagination
   const handleChangePage = (event: any, newPage: any) => {
      event.preventDefault();
      setPage(newPage);
   };

   // Change handler for rows per page
   const handleChangeRowsPerPage = (event: any) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };
   const handleChangeSearch = (event: any) => {
      setSearchTerm(event.target.value);
      setPage(0);
   };

   const filteredListings = rows.filter((listing: any) =>
      listing.subdivision
         ? listing.subdivision.toLowerCase().includes(subdivision.toLowerCase())
         : ""
   );

   return (
      <DashboardTemplate title={"Listings Home"}>
         {/* Search input */}
         {/* <TextField
            size='small'
            label='Search Names'
            variant='outlined'
            value={searchTerm}
            onChange={(e) => handleChangeSearch(e)}
            style={{ marginBottom: "1rem" }}
         /> */}
         <FormControl style={{ minWidth: 200, marginLeft: 10 }}>
            <InputLabel id='subdivision-label'>Subdivision</InputLabel>
            <Select
               labelId='subdivision-label'
               value={subdivision}
               onChange={(e) => (setSubdivision(e.target.value), setPage(0))}
            >
               {Array.from(
                  new Set(listings.arr.map((item) => item.subdivision))
               )
                  .sort((a, b) => a.localeCompare(b))
                  .map((subdivision) => (
                     <MenuItem key={subdivision} value={subdivision}>
                        {subdivision}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
         {/* Table container */}
         <TableContainer>
            <Table>
               <TableBody>
                  {/* Render listings for the current page */}
                  {filteredListings
                     .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                     )
                     .map((row: any) => (
                        <TableRow key={row.id}>
                           <TableCell>
                              <ListingBox row={row} />
                           </TableCell>
                        </TableRow>
                     ))}
               </TableBody>
            </Table>
         </TableContainer>
         {/* Pagination controls */}
         <TablePagination
            rowsPerPageOptions={[3, 5, 10, 15]}
            component='div'
            count={filteredListings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </DashboardTemplate>
   );
}
