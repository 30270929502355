import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5xy from "@amcharts/amcharts5/xy"; // Add this line to import am5xy

import * as am5 from "@amcharts/amcharts5";
import { useEffect } from "react";
import { testingData } from "./testData";
import { isolateAttributeName } from "./utilities/chartGeneral";

export default function BarChart(props: any) {
   const data: any = props.arr || [];

   const fieldVar = isolateAttributeName(data[0]);
   // create a array called data which

   useEffect(() => {
      let root: any = am5.Root.new("chartdiv");
      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
         am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX",
            pinchZoomX: true,
            paddingLeft: 0,
            paddingRight: 1,
         })
      );

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
      cursor.lineY.set("visible", false);

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let xRenderer = am5xy.AxisRendererX.new(root, {
         minGridDistance: 30,
         minorGridEnabled: true,
      });

      xRenderer.labels.template.setAll({
         rotation: -90,
         centerY: am5.p50,
         centerX: am5.p100,
         paddingRight: 15,
      });

      xRenderer.grid.template.setAll({
         location: 1,
      });

      let xAxis = chart.xAxes.push(
         am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: fieldVar,
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
         })
      );

      let yRenderer = am5xy.AxisRendererY.new(root, {
         strokeOpacity: 0.1,
      });

      let yAxis = chart.yAxes.push(
         am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: yRenderer,
         })
      );

      // Create series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      let series = chart.series.push(
         am5xy.ColumnSeries.new(root, {
            name: "Series 1",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            sequencedInterpolation: true,
            categoryXField: fieldVar,
            tooltip: am5.Tooltip.new(root, {
               labelText: "{valueY}",
            }),
         })
      );

      series.columns.template.setAll({
         cornerRadiusTL: 5,
         cornerRadiusTR: 5,
         strokeOpacity: 0,
      });
      series.columns.template.adapters.add(
         "fill",
         function (fill: any, target: any) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
         }
      );

      series.columns.template.adapters.add(
         "stroke",
         function (stroke: any, target: any) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
         }
      );

      // Set data

      xAxis.data.setAll(data);
      series.data.setAll(data);

      // Make stuff animate on load
      series.appear(1000);
      chart.appear(1000, 100);
      return () => {
         // Cleanup code, such as disposing the chart or root, if needed
         chart.dispose();
         root.dispose();
      };
   });
   // Create chart
   // https://www.amcharts.com/docs/v5/charts/xy-chart/

   return (
      <>
         <div
            id='chartdiv'
            style={{ width: "100%", height: "500px", backgroundColor: "white" }}
         ></div>
      </>
   );
}
