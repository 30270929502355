import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import {
   clearSnackbar,
   addSnackbarMsg,
} from "../../../features/snackbar/snackbarSlice";
import {
   clearSession,
   setSession,
} from "../../../features/session/sessionSlice";
import { msg } from "../../../utilities/gen";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { SessionState } from "../../../features/session/session";
import { clearAgents } from "../../../features/agents/agentsSlice";
import { clearLinks } from "../../../features/links/linksSlice";
import { clearCompanies } from "../../../features/companies/companiesSlice";
import { clearListings } from "../../../features/listings/listingsSlice";
import { clearOffices } from "../../../features/offices/officesSlice";

export default (): JSX.Element => {
   const navigate = useNavigate();
   const dis = useAppDispatch();
   const session: SessionState = useAppSelector((state: any) => state.session);

   const [anchorSe, setAnchorSe] = useState<null | HTMLElement>(null);
   const openSe = Boolean(anchorSe);
   const [anchorPr, setAnchorPr] = useState<null | HTMLElement>(null);
   const openPr = Boolean(anchorPr);

   const handleMenuClosePr = () => setAnchorPr(null);

   const handleProfile = () => {
      setAnchorPr(null);
      dis(addSnackbarMsg(msg(`Loading Profile`, "info")));
      navigate(`/clients/profile`);
   };

   const handleLogout = (
      event: React.MouseEvent<HTMLButtonElement> | any
   ): void => {
      dis(addSnackbarMsg(msg(`Logging out`, "info")));
      setAnchorPr(event.currentTarget);
      dis(clearAgents());
      dis(clearSnackbar());
      dis(clearSession());
      dis(clearLinks());
      dis(clearCompanies());
      dis(clearListings());
      dis(clearOffices());
      setTimeout(() => navigate(`/login`), 1200);
   };

   const toggleDM = () =>
      dis(setSession({ ...session, darkMode: !session.darkMode }));

   const handleClickPr = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorPr(event.currentTarget);
   };

   return (
      <div
         style={{
            minWidth: 80,
            position: "fixed",
            right: 10,
            top: 4,
            padding: 0,
            margin: 0,
         }}
      >
         <IconButton
            id='basic-button'
            aria-controls={openSe ? "basic-menu" : undefined}
            aria-haspopup='true'
            aria-expanded={openSe ? "true" : undefined}
            onClick={toggleDM}
         >
            {session.darkMode ? (
               <DarkModeIcon style={{ color: "white" }} />
            ) : (
               <LightModeIcon style={{ color: "white" }} />
            )}
         </IconButton>

         <IconButton
            id='basic-button'
            aria-controls={openPr ? "basic-menu" : undefined}
            aria-haspopup='true'
            aria-expanded={openPr ? "true" : undefined}
            onClick={handleClickPr}
         >
            <PersonIcon style={{ color: "white" }} />
         </IconButton>
         <Menu
            id='basic-menu'
            anchorEl={anchorPr}
            open={openPr}
            onClose={handleMenuClosePr}
            MenuListProps={{
               "aria-labelledby": "basic-button",
            }}
         >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={(event) => handleLogout(event)}>Logout</MenuItem>
         </Menu>
      </div>
   );
};
