import React, { useEffect, useRef, useState } from "react";
import L, { map } from "leaflet";

import "leaflet/dist/leaflet.css";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import { Listing } from "../../../features/listings/listings";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

import "leaflet/dist/leaflet.css";
import Selectors from "./widgets/MapSelectors";
import { MapContainer, TileLayer } from "react-leaflet";
import MarkerIconColor from "./widgets/MarkerIconColor";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { msg } from "../../../utilities/gen";

const MapLeaf = () => {
   const dispatch = useAppDispatch();
   const mapRef = useRef<any>(null);
   const listings: Listing[] = useAppSelector(
      (state: RootState) => state.listings.arr
   );
   const [markers, setMarkers] = useState<Listing[]>(listings);
   const [region, setRegion] = useState<any>("All");
   const [property, setProperty] = useState<any>("All");
   const [mapHeight, setMapHeight] = useState(600);
   const [priceRange, setPriceRange] = useState([0, 1200000]);
   const [sizeRange, setSizeRange] = useState([0, 500]);

   useEffect(() => {
      // Clear the map before adding new markers

      mapRef.current?.eachLayer((layer: any) => {
         if (layer instanceof L.Marker) {
            mapRef.current?.removeLayer(layer);
         }
      });
      const filteredMarkers = listings.filter((listing) => {
         const priceInRange =
            parseFloat(listing.price_current) >= priceRange[0] &&
            (priceRange[1] === 1200000 ||
               parseFloat(listing.price_current) <= priceRange[1]);

         const sizeInRange =
            listing.sqft_total >= sizeRange[0] &&
            (sizeRange[1] === 500 || listing.sqft_total >= sizeRange[1]);

         const regionMatch = region === "All" || listing.region === region;
         const propertyMatch =
            property === "All" || listing.property_type === property;

         return priceInRange && sizeInRange && regionMatch && propertyMatch;
      });

      setMarkers(filteredMarkers);
      setMapHeight(mapHeight + 1);
      setTimeout(() => {
         setMapHeight(mapHeight - 1);
      }, 500);
   }, [listings, region, property, priceRange, sizeRange]);

   useEffect(() => {
      //console.log("markers", markers);
   }, [markers]);

   useEffect(() => {
      // This will run when mapHeight changes
   }, [mapHeight]);

   const handleMapHeight = (change: string) => {
      let newMapHeight: number =
         change === "up" ? mapHeight + 50 : mapHeight - 50;
      if (newMapHeight < 1200 && newMapHeight > 200) {
         setMapHeight(newMapHeight);
      } else {
         dispatch(
            addSnackbarMsg(
               msg(
                  "Must be between 200 and 1200 (" +
                     newMapHeight +
                     ") " +
                     new Date(),
                  "warning"
               )
            )
         );
      }
   };

   const handleRangeChangePrice = (event: Event, newValue: any) => {
      setPriceRange(newValue as number[]);
   };

   const handleChangeRangeSize = (event: Event, newValue: any) => {
      setSizeRange(newValue as number[]);
   };

   const handleChangeRegion = (e: any) => {
      setRegion("null");
      setTimeout(() => {
         setRegion(e.target.value);
      }, 500);
   };

   const handleChangeProperty = (e: any) => {
      setProperty("null");
      setTimeout(() => {
         setProperty(e.target.value);
      }, 500);
   };

   return (
      <DashboardTemplate title={"Listings Map View"}>
         <Selectors
            handleChangeRegion={handleChangeRegion}
            handleChangeProperty={handleChangeProperty}
            handleMapHeight={handleMapHeight}
            handleRangeChangePrice={handleRangeChangePrice}
            handleChangeRangeSize={handleChangeRangeSize}
            region={region}
            property={property}
            priceRange={priceRange}
            sizeRange={sizeRange}
         />
         <MapContainer
            key={"i" + mapHeight}
            center={[8.344816, -80.940827]}
            zoom={8.3}
            style={{ height: mapHeight, width: "100%" }}
            ref={mapRef}
            scrollWheelZoom={true}
         >
            <TileLayer
               attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
               url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />
            {markers.map((listing) => {
               const latitude = parseFloat(listing.latitude);
               const longitude = parseFloat(listing.longitude);

               // Check if latitude and longitude are valid numbers
               if (!isNaN(latitude) && !isNaN(longitude)) {
                  return (
                     <MarkerIconColor
                        key={"i" + listing.id}
                        listing={listing}
                     />
                  );
               }
               return null; // Skip rendering if latitude or longitude is invalid
            })}
         </MapContainer>
      </DashboardTemplate>
   );
};

export default MapLeaf;
