import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewListIcon from "@mui/icons-material/ViewList";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BarChartIcon from "@mui/icons-material/BarChart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StoreIcon from "@mui/icons-material/Store";
import LinkIcon from "@mui/icons-material/Link";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MapIcon from "@mui/icons-material/Map";
import StorageIcon from "@mui/icons-material/Storage";
import ImageIcon from "@mui/icons-material/Image";
import { Collapse } from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { SessionState } from "../../../features/session/session";
import {
   ACCESS_ADMINS_MIN,
   ACCESS_AGENTS_MIN,
   ACCESS_CHARTS_MIN,
   ACCESS_COMPANIES_MIN,
   ACCESS_DATA_MIN,
   ACCESS_IMAGES_MIN,
   ACCESS_LINKS_MIN,
   ACCESS_LISTINGS_MIN,
   ACCESS_MAPS_MIN,
   ACCESS_OFFICES_MIN,
} from "../../../constants/access";

interface LeftNavProps {
   goPage: any;
}

const FireNav = styled(List)<{ component?: React.ElementType }>({
   "& .MuiListItemButton-root": {
      paddingLeft: 24,
      paddingRight: 24,
   },
   "& .MuiListItemIcon-root": {
      minWidth: 0,
      marginRight: 16,
   },
   "& .MuiSvgIcon-root": {
      fontSize: 19,
   },
});

export const LeftNav = (props: LeftNavProps) => {
   const dispatch = useAppDispatch();
   const { goPage } = props;
   const userLevel: any = useAppSelector(
      (state) => state.session.user.userLevel
   );
   const session: SessionState = useAppSelector((state) => state.session);
   const agent = session.agent;
   const [openSubmenu, setOpenSubmenu] = useState<string | number>("");
   const handleClick = (index: any) => {
      setOpenSubmenu(openSubmenu === index ? "" : index);
   };
   const handleAdd = (tableName: string) => {
      tableName = tableName.toLowerCase();
      console.log("tableName", tableName);
      dispatch(
         setDialog({
            open: true,
            title: `Add record to ${tableName}`,
            content: "Add",
            params: {
               nullByDefaultFields: ["office_id"],
               numericFields: ["office_id"],
               hiddenFields: [
                  "id",
                  "created_at",
                  "updated_at",
                  "isDeleted",
                  "createdAt",
                  "updatedAt",
                  "last_login",
                  "isAuto",
                  "accessLevel",
                  "featureObj",
               ],
               tableName,
            },
         })
      );
   };

   const menuItems = [
      {
         label: "Dashboard",
         icon: <DashboardIcon />,
         path: "/dashboard",
      },
      {
         label: "Listings",
         icon: <ViewListIcon />,
         submenu: [
            {
               label: "Listings Home",
               path: "/home/listings",
            },
            {
               label: "Grid View",
               path: "/grid/listings",
            },
            {
               label: "View By Map",
               path: "/map/view",
            },
            {
               label: "Add Listing",
            },
         ],
      },
      {
         label: "Agents",
         icon: <PeopleAltIcon />,
         submenu: [
            {
               label: "Home",
               path: "/home/agents",
            },
            {
               label: "Grid View",
               path: "/grid/agents",
            },
            {
               label: "Add Agent",
            },
         ],
      },
      {
         label: "Offices",
         icon: <BusinessIcon />,
         submenu: [
            {
               label: "Grid View",
               path: "/grid/offices",
            },
            {
               label: "Add Office",
            },
         ],
      },
      {
         label: "Links",
         icon: <LinkIcon />,
         submenu: [
            {
               label: "Grid View",
               path: "/grid/links",
            },
            {
               label: "Add Link",
            },
         ],
      },
      {
         label: "Companies",
         icon: <StoreIcon />,
         submenu: [
            {
               label: "Grid View",
               path: "/grid/companies",
            },
            {
               label: "Companies Home",
               path: "/companies",
            },
            {
               label: "Add Company",
            },
         ],
      },
      {
         label: "Maps",
         icon: <MapIcon />,
         submenu: [
            {
               label: "Map Home",
               path: "/map",
            },
            {
               label: "View By Map",
               path: "/viewMap",
            },
            {
               label: "Map Chart Region",
               path: "/map/chart/region",
            },
            {
               label: "Map Chart Points",
               path: "/map/chart/points",
            },
         ],
      },
      {
         label: "Admins",
         icon: <AccountCircleIcon />,
         submenu: [
            {
               label: "Grid View",
               path: "/admins",
            },
            {
               label: "Add Admin",
            },
         ],
      },
      // {
      //    label: "Images",
      //    icon: <ImageIcon />,
      //    submenu: [
      //       {
      //          label: "Images Home",
      //          path: "/home/images",
      //       },
      //    ],
      // },
      {
         label: "Data",
         icon: <StorageIcon />,
         path: "/home/data",
      },
      {
         label: "Charts",
         icon: <BarChartIcon />,
         path: "/home/charts",
      },
   ];
   const filteredMenuItems = menuItems.filter((item) => {
      switch (item.label) {
         case "Agents":
            return agent.accessLevel <= ACCESS_AGENTS_MIN;
         case "Charts":
            return agent.accessLevel <= ACCESS_CHARTS_MIN;
         case "Companies":
            return agent.accessLevel <= ACCESS_COMPANIES_MIN;
         case "Links":
            return agent.accessLevel <= ACCESS_LINKS_MIN;
         case "Listings":
            return agent.accessLevel <= ACCESS_LISTINGS_MIN;
         case "Maps":
            return agent.accessLevel <= ACCESS_MAPS_MIN;
         case "Offices":
            return agent.accessLevel <= ACCESS_OFFICES_MIN;
         // case "Images":
         //    return agent.accessLevel <= ACCESS_IMAGES_MIN;
         case "Data":
            return agent.accessLevel <= ACCESS_DATA_MIN;
         case "Admins":
            return agent.accessLevel <= ACCESS_ADMINS_MIN;
         default:
            return true; // Keep other menu items by default
      }
   });

   return (
      <>
         <FireNav component='nav' disablePadding>
            <List component='nav'>
               {filteredMenuItems.map((item, index) => (
                  <React.Fragment key={index}>
                     {item.submenu ? (
                        <>
                           <ListItemButton
                              onClick={() => handleClick(index)}
                              sx={{
                                 py: 0,
                                 minHeight: 22,
                                 ml: -1.7,
                              }}
                           >
                              <ListItemIcon sx={{ color: "inherit" }}>
                                 {item.icon}
                              </ListItemIcon>
                              <ListItemText
                                 primary={item.label}
                                 primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: "medium",
                                 }}
                              />
                              {openSubmenu === index ? (
                                 <ExpandLess />
                              ) : (
                                 <ExpandMore />
                              )}
                           </ListItemButton>
                           <Collapse
                              in={openSubmenu === index}
                              timeout='auto'
                              unmountOnExit
                           >
                              <List component='div' disablePadding>
                                 {item.submenu.map((subItem, subIndex) => (
                                    <ListItemButton
                                       key={subIndex}
                                       sx={{ pl: 4, py: 0, minHeight: 22 }}
                                       onClick={() => {
                                          if (item.submenu[subIndex].path) {
                                             goPage(subItem.path);
                                          } else {
                                             handleAdd(item.label); // Or whichever property in the item object contains the table name
                                          }
                                       }}
                                    >
                                       <ListItemIcon sx={{ color: "inherit" }}>
                                          <ChevronRightIcon />
                                       </ListItemIcon>
                                       <ListItemText
                                          primary={subItem.label}
                                          primaryTypographyProps={{
                                             fontSize: 16,
                                             fontWeight: "medium",
                                          }}
                                       />
                                    </ListItemButton>
                                 ))}
                              </List>
                           </Collapse>
                        </>
                     ) : (
                        <Tooltip title={item.label} placement='right'>
                           <ListItemButton
                              key={item.label}
                              sx={{
                                 py: 0,
                                 minHeight: 22,
                                 ml: -1.7,
                              }}
                              onClick={() => goPage(item.path)}
                           >
                              <ListItemIcon sx={{ color: "inherit" }}>
                                 {item.icon}
                              </ListItemIcon>
                              <ListItemText
                                 primary={item.label}
                                 primaryTypographyProps={{
                                    fontSize: 16,
                                    fontWeight: "medium",
                                 }}
                              />
                           </ListItemButton>
                        </Tooltip>
                     )}
                  </React.Fragment>
               ))}
            </List>
         </FireNav>
      </>
   );
};
