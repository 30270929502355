import * as React from "react";
import Box from "@mui/material/Box";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { OfficesState } from "../../../features/offices/offices";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";

import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";

const formatDate = (dateString: number | string) => {
   const date = new Date(dateString);
   return date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "short",
      day: "2-digit",
   });
};

export default function () {
   const dispatch = useAppDispatch();
   const offices: OfficesState = useAppSelector((state: any) => state.offices);
   const rows = offices.arr;

   const handleEdit = (id: number) => {
      const editRow = offices.arr.filter((a) => a.id === id);
      console.log(editRow[0]);
      dispatch(
         setDialog({
            open: true,
            title: "Edit Office",
            content: "Edit",
            params: {
               row: editRow[0],
               tableName: "offices",
            },
         })
      );
      // Handle edit action
   };

   const handleDelete = (id: number) => {
      // Handle delete action
   };

   const handleView = (id: number) => {
      const viewRow = offices.arr.filter((a) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: `View Office`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "offices",
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "Actions",
         width: 120,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.id)}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleDelete(params.row.id)}
               >
                  <DeleteIcon fontSize='inherit' />
               </IconButton>
            </>
         ),
      },
      { field: "id", headerName: "ID", width: 70 },
      { field: "name", headerName: "Name", width: 200 },
      { field: "address_line_1", headerName: "Address", width: 250 },
      { field: "license_number", headerName: "License Number", width: 150 },
      { field: "phone", headerName: "Phone", width: 150 },
      { field: "phone_2", headerName: "Phone 2", width: 150 },
      { field: "toll_free", headerName: "Toll Free", width: 150 },
      { field: "fax", headerName: "Fax", width: 150 },
      { field: "email", headerName: "Email", width: 200 },
      { field: "url", headerName: "URL", width: 200 },
      {
         field: "createdAt",
         headerName: "Created At",
         width: 180,
         valueFormatter: (params: any) => formatDate(params.value),
      },
      {
         field: "updatedAt",
         headerName: "Updated At",
         width: 180,
         valueFormatter: (params: any) => formatDate(params.value),
      },
      { field: "featureObj", headerName: "Feature Object", width: 200 },
      { field: "isAuto", headerName: "Is Auto", width: 120 },
      { field: "isDeleted", headerName: "Is Deleted", width: 120 },
   ];

   React.useEffect(() => {
      // reload if offices state changes
   }, [offices.arr]);

   return (
      <DashboardTemplate title={"Office Grid View"}>
         <DataGrid
            density='compact'
            rows={rows}
            columns={columns}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
         />
      </DashboardTemplate>
   );
}
