import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5map from "@amcharts/amcharts5/map";
import * as am5 from "@amcharts/amcharts5";
import am5geodata_data_countries2 from "@amcharts/amcharts5-geodata/data/countries2";
import { useEffect } from "react";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { mapCoords } from "./utilities/mapChartConstants";
import React from "react";
import { cities } from "../../../constants/data/cities";

interface RegionCount {
   region: string;
   count: number;
}

export default function MapChartRegion() {
   const listings: any = useAppSelector(
      (state: RootState) => state.listings.arr
   );
   const citySeries = React.useRef<any>(null);

   useEffect(() => {
      let root = am5.Root.new("chartDivRegion");

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
         am5map.MapChart.new(root, {
            panX: "rotateX",
            projection: am5map.geoMercator(),
            layout: root.horizontalLayout,
         })
      );

      chart.set(
         "zoomControl",
         am5map.ZoomControl.new(root, {
            layout: root.verticalLayout,
            paddingRight: 30,
            paddingBottom: 30,
            opacity: 0.5,
         })
      );

      loadGeodata("PA");

      // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
      let polygonSeries = chart.series.push(
         am5map.MapPolygonSeries.new(root, {
            calculateAggregates: true,
            valueField: "value",
         })
      );

      polygonSeries.mapPolygons.template.setAll({
         tooltipText: "{name}",
         interactive: true,
      });

      polygonSeries.mapPolygons.template.states.create("hover", {
         fill: am5.color(0xff0000),
      });

      polygonSeries.set("heatRules", [
         {
            target: polygonSeries.mapPolygons.template,
            dataField: "value",
            min: am5.color(0xa1c3ff),
            max: am5.color(0x07285c),
            key: "fill",
         },
      ]);

      polygonSeries.mapPolygons.template.events.on(
         "pointerover",
         function (ev: any) {
            heatLegend.showValue(ev.target.dataItem.get("value"));
         }
      );

      function loadGeodata(country: any) {
         country == "US"
            ? chart.set("projection", am5map.geoAlbersUsa())
            : chart.set("projection", am5map.geoMercator());

         // calculate which map to be used
         let title = "",
            currentMap;
         if (am5geodata_data_countries2[country] !== undefined) {
            currentMap = am5geodata_data_countries2[country]["maps"][0];

            // add country title
            if (am5geodata_data_countries2[country]["country"]) {
               title = am5geodata_data_countries2[country]["country"];
            }
         }

         am5.net
            .load("/assets/json/panamaHigh.json", chart)
            .then(function (result: any) {
               let geodata = am5.JSONParser.parse(result.response);
               let data = mapCoords;

               //   const listings: Listing[] = /* Your listings array */;
               const tally: RegionCount[] = [];

               // Create a map to store the tally of listings for each region
               const regionTallyMap = listings.reduce(
                  (acc: any, listing: any) => {
                     const { region } = listing;
                     acc[region] = (acc[region] || 0) + 1;
                     return acc;
                  },
                  {} as Record<string, number>
               );

               // Convert the regionTallyMap to the tally array
               for (const region in regionTallyMap) {
                  if (regionTallyMap.hasOwnProperty(region)) {
                     tally.push({ region, count: regionTallyMap[region] });
                  }
               }

               const tallyMap = new Map(
                  tally.map(({ region, count }) => [region, count])
               );

               // Update data array with tally counts
               data.forEach((item) => {
                  const count = tallyMap.get(item.name);
                  if (count !== undefined) {
                     item.value = count;
                  }
               });

               polygonSeries.set("geoJSON", geodata);
               polygonSeries.data.setAll(data);
            });

         chart.seriesContainer.children.push(
            am5.Label.new(root, {
               x: 5,
               y: 5,
               text: title,
               background: am5.RoundedRectangle.new(root, {
                  fill: am5.color(0xffffff),
                  fillOpacity: 0.2,
               }),
            })
         );
      }

      let heatLegend = chart.children.push(
         am5.HeatLegend.new(root, {
            orientation: "vertical",
            startColor: am5.color(0xcfe1ff),
            endColor: am5.color(0x07285c),
            startText: "Lowest",
            endText: "Highest",
            stepCount: 5,
         })
      );

      heatLegend.startLabel.setAll({
         fontSize: 12,
         fill: heatLegend.get("startColor"),
      });

      heatLegend.endLabel.setAll({
         fontSize: 12,
         fill: heatLegend.get("endColor"),
      });

      // change this to template when possible
      polygonSeries.events.on("datavalidated", function () {
         heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
         heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
      });

      citySeries.current = chart.series.push(
         am5map.MapPointSeries.new(root, {})
      );
      citySeries.current.bullets.push(function () {
         let container = am5.Container.new(root, {});
         let circle = container.children.push(
            am5.Circle.new(root, {
               radius: 7,
               opacity: 0.8,
               fill: am5.color(0xcccc99), // Use red color for the fill
               stroke: root.interfaceColors.get("background"),
               strokeWidth: 0,
            })
         );
         let cityLabel = container.children.push(
            am5.Label.new(root, {
               text: "{title}",
               paddingLeft: 8,
               populateText: true,
               fontWeight: "bold",
               fontSize: 12,
               centerY: am5.p50,
            })
         );
         circle.on("radius", function (radius) {
            cityLabel.set("x", radius);
         });

         return am5.Bullet.new(root, {
            sprite: container,
            dynamic: true,
         });
      });

      chart.zoomIn();
      setTimeout(() => chart.zoomOut(), 500);
      // chart.appear(2000, 600);

      cities.forEach((item: any) => {
         addCity(
            item.long as number,
            item.lat as number,
            item.name as string,
            "Point"
         );
      });

      return () => {
         // Cleanup code, such as disposing the chart or root, if needed
         chart.dispose();
         root.dispose();
      };
   });

   function addCity(
      longitude: number,
      latitude: number,
      title: string,
      type = "Point"
   ) {
      citySeries.current.data.push({
         geometry: { type, coordinates: [longitude, latitude] },
         title: title,
      });
   }

   return (
      <>
         <DashboardTemplate title={"Map Chart Region"}>
            <div
               id='chartDivRegion'
               style={{
                  width: "100%",
                  height: "600px",
                  backgroundColor: "white",
               }}
            ></div>
         </DashboardTemplate>
      </>
   );
}
