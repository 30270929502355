import { Button, Card, CardMedia, Grid } from "@mui/material";
import { IMG_PATH } from "../../../constants/api";
import { Listing } from "../../../features/listings/listings";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { msg } from "../../../utilities/gen";

interface ImageBoxProps {
   row: any;
   imgHeight: number;
}

export const ImageBox = (props: ImageBoxProps) => {
   const { row, imgHeight } = props;
   const dispatch = useAppDispatch();
   const listings: Listing[] = useAppSelector(
      (state: any) => state.listings.arr
   );

   const handleClickListing = (imageName: string) => {
      const unique_id = imageName.slice(4, 8);
      console.log(unique_id);
      const viewRow = listings.filter((a) => a.unique_id.includes(unique_id));
      // check to see if there is a value in viewRow
      if (viewRow.length === 0) {
         console.log();
         dispatch(
            addSnackbarMsg(
               msg("No listing found for this image: " + imageName, "error")
            )
         );
         return;
      }
      dispatch(addSnackbarMsg(msg("Listing found for this image.", "success")));
      dispatch(
         setDialog({
            open: true,
            title: `View Listing`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "listings",
            },
         })
      );
   };
   const handleCardClick = (imageName: string) => {
      // Open the image in a new window
      window.open(IMG_PATH + "/" + imageName, "_blank");
   };
   return (
      <Grid container spacing={1} alignItems='center'>
         <Grid item xs={6}>
            <Card
               onClick={() => handleCardClick(row.imageName)}
               style={{ cursor: "pointer" }}
            >
               <CardMedia
                  component='img'
                  alt='My Image'
                  height={imgHeight}
                  image={IMG_PATH + "/" + row.imageName}
               />
            </Card>
         </Grid>
         <Grid item xs={4}>
            {row.imageName}
            <br />

            {row.createdAt}
            <br />
            <Button
               variant='contained'
               color='primary'
               onClick={() => handleClickListing(row.imageName)}
               component='a'
            >
               View Listing ID: {row.imageName.slice(4, 8)}
            </Button>
         </Grid>
      </Grid>
   );
};
