export function countDistinct(arr: any[], field: string) {
   interface ChartDataType {
      field: string;
      value: number;
   }
   let data: ChartDataType[] = [];

   // Create a map to store counts of distinct values
   const countMap: Map<any, number> = new Map();

   // Loop through the array to count distinct values
   for (const item of arr) {
      const value = item[field];
      countMap.set(value, (countMap.get(value) || 0) + 1);
   }

   // Convert the map into the desired data format
   countMap.forEach((count, field) => {
      data.push({ field: String(field), value: count }); // Adjusted to have "value" instead of "count"
   });

   // Sort the data by value in descending order
   data.sort((a, b) => b.value - a.value); // Adjusted to sort by "value"

   return data;
}

export function countDistinctLarge(arr: any[], field: string, min: number) {
   interface ChartDataType {
      field: string;
      value: number;
   }
   let data: ChartDataType[] = [];

   // Create a map to store counts of distinct values
   const countMap: Map<any, number> = new Map();

   // Loop through the array to count distinct values
   for (const item of arr) {
      const value = item[field];
      countMap.set(value, (countMap.get(value) || 0) + 1);
   }

   // Convert the map into the desired data format
   countMap.forEach((count, field) => {
      data.push({ field: String(field), value: count }); // Adjusted to have "value" instead of "count"
   });

   // Sort the data by value in descending order
   data.sort((a, b) => b.value - a.value); // Adjusted to sort by "value"

   // Consolidate objects with count of "1" into a single object named "Other"
   const otherCount = data
      .filter((obj) => obj.value === 1)
      .reduce((acc, obj) => acc + obj.value, 0);
   data = data.filter((obj) => obj.value > min); // Remove objects with count of "1"
   data.push({ field: "Other", value: otherCount }); // Add "Other" object

   return data;
}

export function propertiesInPricePoint(properties: any) {
   // Initialize an object to store the count of properties in each price point
   const pricePoints: any = {};

   // Iterate through each property
   properties.forEach((property: any) => {
      // Get the price of the current property
      const price = property.price_current;

      // Calculate the price point for the current property
      let pricePoint: string;
      if (price >= 1000000) {
         pricePoint = Math.floor(price / 1000000) + "M"; // Convert to millions
      } else {
         pricePoint = Math.floor(price / 50000) * 50 + "k"; // Round down to the nearest 50k and convert to thousands
      }

      // Update the count of properties in the corresponding price point
      if (pricePoints[pricePoint]) {
         pricePoints[pricePoint]++;
      } else {
         pricePoints[pricePoint] = 1;
      }
   });

   // Convert the pricePoints object to an array of objects
   let pricePointArray: any = Object.keys(pricePoints).map((key) => ({
      pricePoint: key,
      value: pricePoints[key],
   }));

   // Sort the pricePointArray
   pricePointArray.sort((a: any, b: any) => {
      const pricePointA = a.pricePoint.endsWith("M")
         ? parseFloat(a.pricePoint) * 1000
         : parseFloat(a.pricePoint);
      const pricePointB = b.pricePoint.endsWith("M")
         ? parseFloat(b.pricePoint) * 1000
         : parseFloat(b.pricePoint);
      return pricePointA - pricePointB;
   });

   // Adjust the pricePointArray to include the range
   pricePointArray = pricePointArray.map((item: any, index: number) => {
      let range;
      if (index === 0) {
         range = `0-${item.pricePoint}`;
      } else {
         const previousPricePoint = pricePointArray[index - 1].pricePoint;
         range = `${previousPricePoint}-${item.pricePoint}`;
      }
      return { range, value: item.value };
   });

   return pricePointArray;
}

export function isolateAttributeName(obj: any) {
   for (const key in obj) {
      if (key !== "values") {
         return key;
      }
   }
   return null; // If there is no attribute other than "values"
}
