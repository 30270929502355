import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AdminsState } from "./admins";

const initialState: AdminsState = {
   arr: [],
   init: false,
};

export const adminsSlice = createSlice({
   name: "admins",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setAdmins: (state, action: PayloadAction<AdminsState>) => {
         // Clear the state before setting new admins
         state.arr.splice(0, state.arr.length);
         // Push each new admin into the state
         action.payload.arr.forEach((admin) => {
            state.arr.push(admin);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleAdmin: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the admin with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((admin: any) => admin.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleAdmin: (state, action: PayloadAction<AdminsState>) => {
         // Add a single admin
         state.arr.push(action.payload.arr[0]);
      },
      clearAdmins: (state) => {
         // Clear the state
         state.arr.splice(0, state.arr.length);
         state.init = false;
      },
   },
});

export const { setAdmins, clearAdmins, updateSingleAdmin, addSingleAdmin } =
   adminsSlice.actions;
export const selectAdmins = (state: RootState) => state.admins;

export default adminsSlice.reducer;
