import { Box, Button, Chip, Grid, LinearProgress, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

interface LoadBoxProps {
   objArr: any[];
   msg: string | any;
   init: boolean;
   tableName: string;
   // handleReload: (type: string) => void;
}

const DemoPaper = styled(Paper)(({ theme }) => ({
   width: "100%",
   padding: theme.spacing(2),
   ...theme.typography.body1,
   textAlign: "left",
   m: 2,
}));

export default function (props: LoadBoxProps) {
   const { objArr, msg, init, tableName } = props;

   return (
      <div style={{ marginBottom: 10 }}>
         <DemoPaper variant='outlined'>
            {!init ? (
               <Box sx={{ width: "100%" }}>
                  Loading {tableName}
                  <LinearProgress />
               </Box>
            ) : (
               <>
                  <Grid container spacing={0}>
                     <Grid item xs={1}>
                        {msg}
                     </Grid>
                     <Grid item xs={7}>
                        <Chip label={tableName} /> loaded - {objArr.length || 0}{" "}
                        records
                     </Grid>
                     <Grid item xs={4} style={{ textAlign: "right" }}>
                        {/* <Button
                           onClick={() => handleReload(tableName)}
                           size='small'
                           variant='contained'
                        >
                           Reload
                        </Button> */}
                     </Grid>
                  </Grid>
               </>
            )}
         </DemoPaper>
      </div>
   );
}
