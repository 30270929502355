import * as React from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ListingsState } from "../../../features/listings/listings";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";

import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";

// set this up based on the object types in ListingsState

export default function () {
   const dispatch = useAppDispatch();
   const listings: ListingsState = useAppSelector(
      (state: any) => state.listings
   );
   const rows = listings.arr;

   const handleEdit = (id: number) => {
      const editRow = listings.arr.filter((a) => a.id === id);
      console.log(editRow[0]);
      dispatch(
         setDialog({
            open: true,
            title: "Edit Listing",
            content: "Edit",
            params: {
               row: editRow[0],
               tableName: "listings",
            },
         })
      );
      // Handle edit action
   };

   const handleDelete = (id: number) => {
      // Handle delete action
   };

   const handleView = (id: number) => {
      const viewRow = listings.arr.filter((a) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: `View Listing`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "listings",
               imgColName: "images",
               imgWidth: "160px",
               imgHeight: "100px",
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "Actions",
         width: 120,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <IconButton
                  aria-label='edit'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.id)}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='view'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleDelete(params.row.id)}
               >
                  <DeleteIcon fontSize='inherit' />
               </IconButton>
            </>
         ),
      },
      { field: "id", headerName: "ID", width: 70 },
      { field: "street_name", headerName: "Street Name", width: 150 },
      { field: "unit_number", headerName: "Unit Number", width: 150 },
      {
         field: "price_current",
         headerName: "Current Price",
         width: 150,
         valueFormatter: (params: any) => {
            if (!params || params.value == null) {
               return "";
            }
            return new Intl.NumberFormat("en-US", {
               style: "currency",
               currency: "USD",
            }).format(Number(params.value));
         },
      },
      {
         field: "price_current_rent",
         headerName: "Current Price Rent",
         width: 180,
         valueFormatter: (params: any) =>
            params && params.value
               ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                 }).format(Number(params.value))
               : "",
      },
      { field: "subdivision", headerName: "Subdivision", width: 150 },
      { field: "region", headerName: "Region", width: 150 },
      { field: "district", headerName: "District", width: 150 },
      { field: "map_area", headerName: "Map Area", width: 150 },
      { field: "property_type", headerName: "Property Type", width: 150 },
      { field: "year_built", headerName: "Year Built", width: 150 },
      { field: "bedrooms", headerName: "Bedrooms", width: 150 },
      { field: "bathrooms", headerName: "Bathrooms", width: 150 },
      { field: "half_bathrooms", headerName: "Half Bathrooms", width: 150 },
      { field: "lot_sqft", headerName: "Lot Sqft", width: 150 },
      { field: "sqft_total", headerName: "Total Sqft", width: 150 },
      { field: "closed_area", headerName: "Closed Area", width: 150 },
      { field: "open_area", headerName: "Open Area", width: 150 },
      { field: "remodelled", headerName: "Remodelled", width: 150 },
      { field: "possession", headerName: "Possession", width: 150 },
      { field: "zoning", headerName: "Zoning", width: 150 },
      { field: "remarks", headerName: "Remarks", width: 200 },
      {
         field: "listing_office_id",
         headerName: "Listing Office ID",
         width: 180,
      },
      { field: "listing_agent_id", headerName: "Listing Agent ID", width: 180 },
      {
         field: "listing_agent_phone",
         headerName: "Listing Agent Phone",
         width: 180,
      },
      { field: "unique_id", headerName: "Unique ID", width: 150 },
      { field: "date_listed", headerName: "Date Listed", width: 150 },
      { field: "status", headerName: "Status", width: 120 },
      { field: "title", headerName: "Title", width: 150 },
      {
         field: "monthly_assessment",
         headerName: "Monthly Assessment",
         width: 200,
      },
      {
         field: "assessment_includes",
         headerName: "Assessment Includes",
         width: 200,
      },
      { field: "date_expired", headerName: "Date Expired", width: 150 },
      { field: "lot_features", headerName: "Lot Features", width: 150 },
      {
         field: "interior_features",
         headerName: "Interior Features",
         width: 200,
      },
      {
         field: "exterior_features",
         headerName: "Exterior Features",
         width: 200,
      },
      { field: "other_services", headerName: "Other Services", width: 200 },
      { field: "lot_shape", headerName: "Lot Shape", width: 150 },
      {
         field: "modification_date",
         headerName: "Modification Date",
         width: 180,
      },
      { field: "web_title", headerName: "Web Title", width: 150 },
      { field: "access", headerName: "Access", width: 150 },
      { field: "roof", headerName: "Roof", width: 150 },
      { field: "cooling", headerName: "Cooling", width: 150 },
      { field: "flooring", headerName: "Flooring", width: 150 },
      { field: "water", headerName: "Water", width: 150 },
      { field: "construction", headerName: "Construction", width: 150 },
      { field: "parking_spaces", headerName: "Parking Spaces", width: 180 },
      { field: "parking_level", headerName: "Parking Level", width: 150 },
      { field: "parking_types", headerName: "Parking Types", width: 180 },
      {
         field: "internal_features",
         headerName: "Internal Features",
         width: 200,
      },
      {
         field: "listing_photo_count",
         headerName: "Listing Photo Count",
         width: 200,
      },
      { field: "building_name", headerName: "Building Name", width: 180 },
      { field: "latitude", headerName: "Latitude", width: 150 },
      { field: "longitude", headerName: "Longitude", width: 150 },
      { field: "units_per_floor", headerName: "Units per Floor", width: 180 },
      {
         field: "crea_display_address",
         headerName: "CREA Display Address",
         width: 200,
      },
      { field: "property_name", headerName: "Property Name", width: 200 },
   ];

   React.useEffect(() => {
      // reload if listings state changes
   }, [listings.arr]);

   return (
      <DashboardTemplate title={"Listings Grid View"}>
         <DataGrid
            density='compact'
            rows={rows}
            columns={columns}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
         />
      </DashboardTemplate>
   );
}
