import * as React from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { AgentsState } from "../../../features/agents/agents";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";

import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import KeyIcon from "@mui/icons-material/Key";
import {
   FormControl,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
} from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { apiPost } from "../../../utilities/ApiRequest";
import { API_URL } from "../../../constants/api";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { msg } from "../../../utilities/gen";
import { setAgents } from "../../../features/agents/agentsSlice";
import Key from "@mui/icons-material/Key";

const formatDate = (dateString: number | string) => {
   const date = new Date(dateString);
   return date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "short",
      day: "2-digit",
   });
};

// set this up based on the object types in AgentsState

export default function AgentsGrid() {
   const dispatch = useAppDispatch();
   const agents: AgentsState = useAppSelector((state: any) => state.agents);
   const session: any = useAppSelector((state) => state.session);
   const token = session.user.token;
   const rows = agents.arr;

   const handleEdit = (id: string) => {
      const editRow = agents.arr.filter((a) => a.id === id);
      console.log(editRow[0]);
      dispatch(
         setDialog({
            open: true,
            title: "Edit Agent",
            content: "Edit",
            params: {
               row: editRow[0],
               tableName: "agents",
            },
         })
      );
      // Handle edit action
   };

   /* eslint-disable no-restricted-globals */
   // const handleDelete = async (row: any) => {
   //    const { id, isDeleted } = row;
   //    try {
   //       if (confirm("Are you sure you want to proceed?")) {
   //          const newValue = isDeleted === 1 ? 0 : 1;
   //          const newAccessLevel = isDeleted === 1 ? 8 : 9;
   //          await apiPost(API_URL + "/crud/updatebyid", {
   //             token,
   //             tableName: "agents",
   //             updateFields: ["isDeleted", "accessLevel"],
   //             updateValues: [newValue, newAccessLevel],
   //             id,
   //          });
   //          dispatch(
   //             addSnackbarMsg(msg("Agent delete status toggled", "success"))
   //          );
   //          const updAgents = agents.arr.map((a) => {
   //             if (a.id === id) {
   //                // Create a new object or clone the existing one
   //                const updatedAgent = { ...a }; // Using object spread syntax to clone
   //                updatedAgent.isDeleted = newValue;
   //                updatedAgent.accessLevel = newAccessLevel;
   //                return updatedAgent;
   //             }
   //             return a;
   //          });
   //          dispatch(setAgents({ init: true, arr: updAgents }));
   //       } else {
   //          dispatch(
   //             addSnackbarMsg(msg("Agent deleted status not changed", "info"))
   //          );
   //          return;
   //       }
   //    } catch (error) {
   //       console.log(error);
   //       dispatch(addSnackbarMsg(msg("Error delete toggle of agent", "error")));
   //    }
   // };
   /* eslint-disable no-restricted-globals */
   //const handleToggleUser = async (row: any) => {
   // const { id, email, first_name, last_name } = row;
   // // generate password:
   // const password = Math.random().toString(36).slice(-8);
   // try {
   //    if (confirm("Are you sure you want to proceed?")) {
   //       await apiPost(API_URL + "/agents_register", {
   //          first_name,
   //          last_name,
   //          email,
   //          password,
   //          userLevel: "5", // '1' highest '5' lowest
   //          agent_id: id,
   //       });
   //       dispatch(
   //          addSnackbarMsg(msg("Agent delete status toggled", "success"))
   //       );
   //       // reset user state by fetching all users
   //    } else {
   //       dispatch(
   //          addSnackbarMsg(
   //             msg("Agent toggle user status not changed", "info")
   //          )
   //       );
   //       return;
   //    }
   // } catch (error) {
   //    console.log(error);
   //    dispatch(
   //       addSnackbarMsg(msg("Error toggling user status of agent", "error"))
   //    );
   // }
   //};
   /* eslint-enable no-restricted-globals */

   const handleView = (id: string) => {
      const viewRow = agents.arr.filter((a) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: `View Agent`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "agents",
            },
         })
      );
   };

   const handleLevelChange = async (e: any, id: string) => {
      const { value } = e.target;
      try {
         await apiPost(API_URL + "/crud/updatebyid", {
            token,
            tableName: "agents",
            updateFields: ["accessLevel"],
            updateValues: [value],
            id,
         });
         dispatch(addSnackbarMsg(msg("Agent access level updated", "success")));
         const updAgents = agents.arr.map((a) => {
            if (a.id === id) {
               // Create a new object or clone the existing one
               const updatedAgent = { ...a }; // Using object spread syntax to clone
               updatedAgent.accessLevel = value;
               return updatedAgent;
            }
            return a;
         });
         dispatch(setAgents({ init: true, arr: updAgents }));
      } catch (error) {
         console.log(error);
         dispatch(
            addSnackbarMsg(msg("Error updating access level of agent", "error"))
         );
      }
   };

   const columns: GridColDef[] = [
      {
         field: "accessLevel",
         headerName: "Level",
         width: 130,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               {params.row.accessLevel === 1 ? (
                  <>Super Admin</>
               ) : (
                  <FormControl variant='filled' sx={{ ml: -1, minWidth: 130 }}>
                     <InputLabel id={"levelid" + params.row.id}>
                        Level
                     </InputLabel>
                     <Select
                        labelId='level'
                        size='small'
                        id={"level" + params.row.id}
                        value={params.row.accessLevel}
                        onChange={(e) => handleLevelChange(e, params.row.id)}
                        label='Level'
                     >
                        <MenuItem value=''>
                           <em>None</em>
                        </MenuItem>
                        <MenuItem value={1}>1 Super Admin</MenuItem>
                        <MenuItem value={2}>2 Admin</MenuItem>
                        <MenuItem value={8}>8 Login</MenuItem>
                        <MenuItem value={9}>9 No Login</MenuItem>
                     </Select>
                  </FormControl>
               )}
            </>
         ),
      },
      {
         field: "actions",
         headerName: "Actions",
         width: 120,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               {/* First IconButton */}
               <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.id)}
                  disabled={parseInt(params.row.accessLevel) === 1}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton>

               {/* Second IconButton */}
               <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>

               {/* Conditional icons */}
               {parseInt(params.row.accessLevel) === 9 ? (
                  <KeyOffIcon color='error' />
               ) : (
                  <KeyIcon
                     color={
                        parseInt(params.row.accessLevel) === 1
                           ? "success"
                           : "warning"
                     }
                  />
               )}
            </>
         ),
      },
      { field: "id", headerName: "ID", width: 70 },
      {
         field: "full_name",
         headerName: "Full Name",
         width: 200,
         valueGetter: (params: any) => {
            if (params && params.row) {
               return `${params.row.first_name || "NA"} ${
                  params.row.last_name || "NA"
               }`;
            } else {
               return "NA";
            }
         },
      },
      { field: "license_number", headerName: "License Number", width: 150 },
      { field: "cell", headerName: "Cell", width: 150 },
      { field: "cell2", headerName: "Cell 2", width: 150 },
      { field: "email", headerName: "Email", width: 200 },
      { field: "url", headerName: "URL", width: 200 },
      { field: "office_id", headerName: "Office ID", width: 120 },
      {
         field: "createdAt",
         headerName: "Created At",
         width: 180,
         valueFormatter: (params: any) => formatDate(params.value),
      },
      {
         field: "updatedAt",
         headerName: "Updated At",
         width: 180,
         valueFormatter: (params: any) => formatDate(params.value),
      },
      { field: "phone", headerName: "Phone", width: 150 },
      { field: "phone2", headerName: "Phone 2", width: 150 },
      { field: "featureObj", headerName: "Feature Object", width: 200 },
      { field: "isAuto", headerName: "Is Auto", width: 120 },
      { field: "isDeleted", headerName: "Is Deleted", width: 120 },
   ];

   React.useEffect(() => {
      // reload if agents state changes
      console.log("agents.arr changed");
   }, [agents.arr]);

   return (
      <DashboardTemplate title={"Agents Grid View"}>
         <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
         />
      </DashboardTemplate>
   );
}
