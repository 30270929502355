export const mapCoords = [
   {
      id: "PA-1",
      value: 0,
      geometry: {
         type: "MultiPolygon",
         coordinates: [
            [
               [
                  [-82.2901, 9.2979],
                  [-82.2371, 9.2532],
                  [-82.2646, 9.234],
                  [-82.2954, 9.2382],
                  [-82.2901, 9.2979],
               ],
            ],
            [
               [
                  [-82.2746, 9.4415],
                  [-82.2343, 9.3837],
                  [-82.2538, 9.3565],
                  [-82.3029, 9.3776],
                  [-82.3193, 9.4293],
                  [-82.2746, 9.4415],
               ],
            ],
            [
               [
                  [-82.084, 8.9277],
                  [-82.1583, 8.8887],
                  [-82.1807, 8.8486],
                  [-82.1977, 8.9163],
                  [-82.1945, 8.9495],
                  [-82.2755, 8.9996],
                  [-82.3258, 9.0097],
                  [-82.3264, 9.0348],
                  [-82.2821, 9.0636],
                  [-82.3234, 9.1241],
                  [-82.3837, 9.026],
                  [-82.4427, 9.0023],
                  [-82.4258, 8.9847],
                  [-82.3661, 8.97],
                  [-82.3453, 8.9505],
                  [-82.3413, 8.893],
                  [-82.3573, 8.8283],
                  [-82.3732, 8.831],
                  [-82.4449, 8.8576],
                  [-82.4884, 8.8839],
                  [-82.5351, 8.8631],
                  [-82.5698, 8.9035],
                  [-82.599, 8.9112],
                  [-82.6538, 8.8844],
                  [-82.7175, 8.9045],
                  [-82.7264, 8.9195],
                  [-82.7134, 8.9499],
                  [-82.7373, 8.991],
                  [-82.7974, 9.0103],
                  [-82.8157, 9.0355],
                  [-82.8762, 9.0644],
                  [-82.8855, 9.0943],
                  [-82.9351, 9.0826],
                  [-82.9385, 9.4727],
                  [-82.8494, 9.4941],
                  [-82.875, 9.5231],
                  [-82.8862, 9.5653],
                  [-82.857, 9.6147],
                  [-82.8358, 9.6165],
                  [-82.7502, 9.5837],
                  [-82.7444, 9.5645],
                  [-82.6985, 9.5341],
                  [-82.6781, 9.4985],
                  [-82.6073, 9.5067],
                  [-82.5891, 9.5571],
                  [-82.5607, 9.5693],
                  [-82.3865, 9.4193],
                  [-82.3507, 9.416],
                  [-82.3535, 9.3829],
                  [-82.3788, 9.3668],
                  [-82.386, 9.3349],
                  [-82.351, 9.3105],
                  [-82.3743, 9.2541],
                  [-82.3373, 9.1957],
                  [-82.2821, 9.1993],
                  [-82.2621, 9.1801],
                  [-82.2193, 9.2246],
                  [-82.1815, 9.1832],
                  [-82.2043, 9.1685],
                  [-82.256, 9.169],
                  [-82.2701, 9.1507],
                  [-82.2699, 9.0957],
                  [-82.246, 9.0835],
                  [-82.249, 9.0099],
                  [-82.2121, 8.9896],
                  [-82.1343, 8.9874],
                  [-82.1079, 8.9315],
                  [-82.084, 8.9277],
               ],
            ],
            [
               [
                  [-82.0005, 8.9571],
                  [-82.0178, 8.9216],
                  [-82.053, 8.8951],
                  [-82.0772, 8.9232],
                  [-82.0696, 8.936],
                  [-82.0005, 8.9571],
               ],
            ],
            [
               [
                  [-82.1254, 9.2376],
                  [-82.0821, 9.1746],
                  [-82.1243, 9.1385],
                  [-82.1588, 9.1582],
                  [-82.1593, 9.191],
                  [-82.1254, 9.2376],
               ],
            ],
            [
               [
                  [-82.2001, 9.3618],
                  [-82.1474, 9.3435],
                  [-82.0996, 9.2924],
                  [-82.1296, 9.2776],
                  [-82.1949, 9.3026],
                  [-82.1743, 9.3337],
                  [-82.2001, 9.3618],
               ],
            ],
         ],
      },
      geometryType: "MultiPolygon",
      name: "Bocas del Toro",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-2",
      value: 0,
      geometry: {
         type: "Polygon",
         coordinates: [
            [
               [-80.7434, 8.6393],
               [-80.6856, 8.6633],
               [-80.7048, 8.7057],
               [-80.6887, 8.7754],
               [-80.6272, 8.8078],
               [-80.5737, 8.7957],
               [-80.5526, 8.8222],
               [-80.5473, 8.9418],
               [-80.445, 8.9018],
               [-80.3662, 8.8848],
               [-80.3567, 8.9086],
               [-80.2387, 8.9694],
               [-80.2135, 8.9723],
               [-80.2088, 9.017],
               [-80.1827, 9.0269],
               [-80.1741, 8.9736],
               [-80.1362, 8.9658],
               [-80.1368, 8.9347],
               [-80.169, 8.8903],
               [-80.1559, 8.7812],
               [-80.1264, 8.7461],
               [-80.1109, 8.6757],
               [-80.1184, 8.6429],
               [-80.1002, 8.5871],
               [-80.111, 8.5417],
               [-80.0922, 8.5152],
               [-80.0981, 8.4709],
               [-80.0597, 8.4012],
               [-80.1807, 8.3282],
               [-80.2485, 8.299],
               [-80.3121, 8.3054],
               [-80.4101, 8.2896],
               [-80.4768, 8.221],
               [-80.4865, 8.179],
               [-80.4826, 8.0935],
               [-80.5103, 8.1012],
               [-80.5748, 8.1229],
               [-80.6291, 8.1136],
               [-80.6739, 8.1178],
               [-80.7095, 8.1662],
               [-80.6843, 8.24],
               [-80.6891, 8.2573],
               [-80.6612, 8.3196],
               [-80.6966, 8.3327],
               [-80.7129, 8.3877],
               [-80.7359, 8.4126],
               [-80.7448, 8.4507],
               [-80.7744, 8.4752],
               [-80.7876, 8.5159],
               [-80.7819, 8.5496],
               [-80.746, 8.6059],
               [-80.7434, 8.6393],
            ],
         ],
      },
      geometryType: "Polygon",
      name: "Coclé",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-3",
      value: 0,
      geometry: {
         type: "Polygon",
         coordinates: [
            [
               [-79.0803, 9.5582],
               [-79.0889, 9.4819],
               [-79.1403, 9.4597],
               [-79.1713, 9.393],
               [-79.1979, 9.3875],
               [-79.2511, 9.3447],
               [-79.2615, 9.4245],
               [-79.3059, 9.4541],
               [-79.3492, 9.4299],
               [-79.3809, 9.444],
               [-79.3774, 9.4726],
               [-79.4527, 9.5233],
               [-79.4827, 9.4887],
               [-79.5315, 9.4813],
               [-79.5423, 9.4226],
               [-79.5669, 9.3769],
               [-79.5563, 9.3468],
               [-79.5935, 9.2821],
               [-79.589, 9.221],
               [-79.6476, 9.2085],
               [-79.6901, 9.1079],
               [-79.6917, 9.1062],
               [-79.744, 9.1196],
               [-79.7644, 9.1072],
               [-79.811, 9.1327],
               [-79.8127, 9.1682],
               [-79.8448, 9.1848],
               [-79.9237, 9.1847],
               [-79.9478, 9.1589],
               [-79.9496, 9.1198],
               [-79.9798, 9.0957],
               [-79.9525, 9.0376],
               [-79.9645, 8.984],
               [-79.9915, 8.971],
               [-80.0513, 8.9764],
               [-80.0922, 9.0032],
               [-80.1362, 8.9658],
               [-80.1741, 8.9736],
               [-80.1827, 9.0269],
               [-80.2088, 9.017],
               [-80.2135, 8.9723],
               [-80.2387, 8.9694],
               [-80.3567, 8.9086],
               [-80.3662, 8.8848],
               [-80.445, 8.9018],
               [-80.5473, 8.9418],
               [-80.5526, 8.8222],
               [-80.5737, 8.7957],
               [-80.6272, 8.8078],
               [-80.6887, 8.7754],
               [-80.7048, 8.7057],
               [-80.6856, 8.6633],
               [-80.7434, 8.6393],
               [-80.7242, 8.66],
               [-80.7548, 8.7271],
               [-80.726, 8.7486],
               [-80.7728, 8.8143],
               [-80.7993, 8.8088],
               [-80.861, 8.8355],
               [-80.874, 8.8823],
               [-80.839, 8.8885],
               [-80.7696, 8.9518],
               [-80.7307, 8.9987],
               [-80.6968, 9.0068],
               [-80.6376, 9.0518],
               [-80.4954, 9.1171],
               [-80.4654, 9.1165],
               [-80.4079, 9.1443],
               [-80.3579, 9.1437],
               [-80.2946, 9.1557],
               [-80.2382, 9.1838],
               [-80.1771, 9.1932],
               [-80.086, 9.2337],
               [-80.0374, 9.2935],
               [-80.0076, 9.3146],
               [-79.979, 9.3626],
               [-79.9465, 9.3676],
               [-79.9476, 9.3132],
               [-79.8821, 9.3604],
               [-79.874, 9.404],
               [-79.8435, 9.3866],
               [-79.7965, 9.4304],
               [-79.744, 9.4471],
               [-79.7251, 9.4835],
               [-79.6907, 9.5029],
               [-79.656, 9.5543],
               [-79.669, 9.5751],
               [-79.6215, 9.6171],
               [-79.5902, 9.606],
               [-79.5524, 9.631],
               [-79.4935, 9.5876],
               [-79.4451, 9.6001],
               [-79.361, 9.5735],
               [-79.2801, 9.569],
               [-79.1974, 9.5407],
               [-79.0803, 9.5582],
            ],
         ],
      },
      geometryType: "Polygon",
      name: "Colón",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-4",
      value: 0,
      geometry: {
         type: "MultiPolygon",
         coordinates: [
            [
               [
                  [-81.5709, 8.3653],
                  [-81.5363, 8.3856],
                  [-81.5139, 8.3565],
                  [-81.5284, 8.3138],
                  [-81.5351, 8.3403],
                  [-81.5709, 8.3653],
               ],
            ],
            [
               [
                  [-82.3582, 8.2796],
                  [-82.3399, 8.2385],
                  [-82.3929, 8.221],
                  [-82.4276, 8.2496],
                  [-82.3582, 8.2796],
               ],
            ],
            [
               [
                  [-81.5709, 8.3653],
                  [-81.6094, 8.3305],
                  [-81.597, 8.2993],
                  [-81.5295, 8.2638],
                  [-81.5573, 8.2335],
                  [-81.5684, 8.1963],
                  [-81.5805, 8.1864],
                  [-81.6118, 8.1586],
                  [-81.6314, 8.0379],
                  [-81.6735, 8.0071],
                  [-81.7099, 8.0504],
                  [-81.7032, 8.109],
                  [-81.7365, 8.1621],
                  [-81.7613, 8.1332],
                  [-81.9201, 8.1865],
                  [-82.0524, 8.2196],
                  [-82.1107, 8.2229],
                  [-82.1393, 8.2032],
                  [-82.1949, 8.2035],
                  [-82.2232, 8.2185],
                  [-82.211, 8.264],
                  [-82.2237, 8.2874],
                  [-82.2696, 8.2904],
                  [-82.3099, 8.3246],
                  [-82.3376, 8.3235],
                  [-82.3514, 8.2904],
                  [-82.4135, 8.284],
                  [-82.444, 8.3212],
                  [-82.4921, 8.2718],
                  [-82.5257, 8.2874],
                  [-82.6593, 8.331],
                  [-82.7285, 8.3265],
                  [-82.8296, 8.2968],
                  [-82.8565, 8.2763],
                  [-82.8771, 8.2212],
                  [-82.8729, 8.1188],
                  [-82.8526, 8.0685],
                  [-82.8958, 8.0376],
                  [-82.8893, 8.1039],
                  [-82.906, 8.1425],
                  [-82.9077, 8.1959],
                  [-82.9399, 8.2733],
                  [-83.0462, 8.3157],
                  [-83.0463, 8.3433],
                  [-82.9792, 8.375],
                  [-82.926, 8.4345],
                  [-82.8589, 8.4495],
                  [-82.8334, 8.5059],
                  [-82.8255, 8.5629],
                  [-82.8279, 8.6268],
                  [-82.8644, 8.6898],
                  [-82.9171, 8.745],
                  [-82.9111, 8.7693],
                  [-82.8695, 8.7965],
                  [-82.8799, 8.8327],
                  [-82.859, 8.8532],
                  [-82.7696, 8.8788],
                  [-82.7264, 8.9195],
                  [-82.7175, 8.9045],
                  [-82.6538, 8.8844],
                  [-82.599, 8.9112],
                  [-82.5698, 8.9035],
                  [-82.5351, 8.8631],
                  [-82.4884, 8.8839],
                  [-82.4449, 8.8576],
                  [-82.3732, 8.831],
                  [-82.3005, 8.8147],
                  [-82.2608, 8.7664],
                  [-82.1471, 8.7604],
                  [-82.1466, 8.6006],
                  [-82.1614, 8.5689],
                  [-82.145, 8.5439],
                  [-82.1776, 8.4834],
                  [-82.1729, 8.4427],
                  [-82.0924, 8.459],
                  [-82.058, 8.4537],
                  [-82.0524, 8.4241],
                  [-82.021, 8.4234],
                  [-82.0199, 8.3612],
                  [-81.9748, 8.3191],
                  [-81.8279, 8.3308],
                  [-81.8316, 8.297],
                  [-81.8035, 8.2718],
                  [-81.7492, 8.2745],
                  [-81.7061, 8.2946],
                  [-81.6649, 8.273],
                  [-81.6291, 8.3051],
                  [-81.6125, 8.3546],
                  [-81.5709, 8.3653],
               ],
            ],
         ],
      },
      geometryType: "MultiPolygon",
      name: "Chiriqui",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-5",
      value: 0,
      geometry: {
         type: "Polygon",
         coordinates: [
            [
               [-78.0488, 9.0895],
               [-77.9863, 9.0723],
               [-77.9576, 9.0409],
               [-77.9155, 9.0206],
               [-77.9132, 8.9999],
               [-77.8348, 8.9075],
               [-77.7554, 8.8703],
               [-77.7348, 8.8344],
               [-77.699, 8.8139],
               [-77.7059, 8.7679],
               [-77.7581, 8.7702],
               [-77.7845, 8.7417],
               [-77.8331, 8.7272],
               [-77.8691, 8.6585],
               [-77.9079, 8.628],
               [-77.8954, 8.5706],
               [-77.8647, 8.5396],
               [-77.8809, 8.5188],
               [-77.8158, 8.463],
               [-77.7951, 8.35],
               [-77.7656, 8.3228],
               [-77.7292, 8.253],
               [-77.6605, 8.2293],
               [-77.6237, 8.2009],
               [-77.5878, 8.1232],
               [-77.5401, 8.0779],
               [-77.4993, 8.0989],
               [-77.4695, 8.0951],
               [-77.4206, 8.1343],
               [-77.2449, 8.1744],
               [-77.218, 8.0883],
               [-77.2263, 8.0546],
               [-77.1993, 8.0055],
               [-77.1868, 7.9236],
               [-77.2507, 7.9276],
               [-77.3211, 7.909],
               [-77.3499, 7.8739],
               [-77.3495, 7.8254],
               [-77.3201, 7.7701],
               [-77.3877, 7.708],
               [-77.4029, 7.7135],
               [-77.4507, 7.6594],
               [-77.4723, 7.6549],
               [-77.4903, 7.6006],
               [-77.5128, 7.5977],
               [-77.5713, 7.5132],
               [-77.6187, 7.5476],
               [-77.6523, 7.6692],
               [-77.7015, 7.7213],
               [-77.7484, 7.7152],
               [-77.7686, 7.6367],
               [-77.7189, 7.5083],
               [-77.7293, 7.4745],
               [-77.7625, 7.4481],
               [-77.815, 7.4503],
               [-77.838, 7.3856],
               [-77.8816, 7.222],
               [-77.8946, 7.2235],
               [-77.9993, 7.3057],
               [-78.0199, 7.334],
               [-78.044, 7.4232],
               [-78.0726, 7.4162],
               [-78.1082, 7.449],
               [-78.1293, 7.4924],
               [-78.1854, 7.5251],
               [-78.1724, 7.5774],
               [-78.2374, 7.6357],
               [-78.2979, 7.7026],
               [-78.2813, 7.7413],
               [-78.336, 7.8396],
               [-78.3449, 7.8724],
               [-78.3932, 7.9065],
               [-78.424, 7.9793],
               [-78.4215, 8.0079],
               [-78.4474, 8.0707],
               [-78.4068, 8.0774],
               [-78.3374, 8.0607],
               [-78.2618, 8.1129],
               [-78.2454, 8.1863],
               [-78.2679, 8.1965],
               [-78.2818, 8.2501],
               [-78.2529, 8.296],
               [-78.2321, 8.2704],
               [-78.184, 8.3035],
               [-78.1918, 8.3346],
               [-78.1599, 8.3863],
               [-78.1357, 8.4054],
               [-78.121, 8.3604],
               [-78.0526, 8.3407],
               [-78.0787, 8.4046],
               [-78.1057, 8.4118],
               [-78.1424, 8.4654],
               [-78.1888, 8.4496],
               [-78.1874, 8.4118],
               [-78.2218, 8.3907],
               [-78.2671, 8.4113],
               [-78.2301, 8.4432],
               [-78.2676, 8.4549],
               [-78.3037, 8.4413],
               [-78.3182, 8.4687],
               [-78.3676, 8.4224],
               [-78.3671, 8.3943],
               [-78.4208, 8.3474],
               [-78.4223, 8.3997],
               [-78.4417, 8.4714],
               [-78.4771, 8.5343],
               [-78.49, 8.6005],
               [-78.4728, 8.6425],
               [-78.5122, 8.7013],
               [-78.4814, 8.7504],
               [-78.4828, 8.8049],
               [-78.4491, 8.8274],
               [-78.404, 8.8376],
               [-78.3618, 8.8321],
               [-78.3334, 8.7956],
               [-78.3026, 8.8155],
               [-78.2402, 8.8761],
               [-78.1752, 8.861],
               [-78.1532, 8.9248],
               [-78.1096, 8.9449],
               [-78.1127, 8.9756],
               [-78.0845, 9.0301],
               [-78.0611, 9.0384],
               [-78.0488, 9.0895],
            ],
            [
               [-78.2398, 7.9866],
               [-78.2574, 7.9961],
               [-78.2968, 7.9653],
               [-78.3262, 7.9638],
               [-78.3278, 7.9249],
               [-78.3357, 7.9024],
               [-78.3169, 7.8591],
               [-78.2759, 7.8062],
               [-78.2685, 7.7696],
               [-78.2335, 7.7151],
               [-78.1817, 7.6646],
               [-78.1459, 7.6793],
               [-78.0944, 7.6482],
               [-78.0632, 7.5812],
               [-78.0415, 7.6082],
               [-78.0077, 7.6192],
               [-77.9881, 7.5908],
               [-77.9448, 7.6233],
               [-77.9439, 7.6774],
               [-77.9568, 7.7354],
               [-77.9479, 7.7604],
               [-77.9764, 7.7951],
               [-78.0063, 7.9035],
               [-78.0333, 7.9518],
               [-78.0772, 7.9179],
               [-78.0866, 7.9655],
               [-78.1075, 7.9779],
               [-78.1186, 8.0177],
               [-78.1885, 8.0943],
               [-78.2337, 8.0657],
               [-78.2027, 8.0066],
               [-78.2262, 7.9831],
               [-78.2398, 7.9866],
            ],
         ],
      },
      geometryType: "Polygon",
      name: "Darién",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-6",
      value: 0,
      geometry: {
         type: "Polygon",
         coordinates: [
            [
               [-80.6739, 8.1178],
               [-80.6291, 8.1136],
               [-80.5748, 8.1229],
               [-80.5103, 8.1012],
               [-80.4696, 8.076],
               [-80.4443, 8.0224],
               [-80.3868, 7.9979],
               [-80.3662, 8.004],
               [-80.3838, 7.9718],
               [-80.4464, 7.9303],
               [-80.4757, 7.937],
               [-80.5227, 7.8946],
               [-80.541, 7.8397],
               [-80.6002, 7.8021],
               [-80.5635, 7.7114],
               [-80.5858, 7.6612],
               [-80.6285, 7.6513],
               [-80.6742, 7.5772],
               [-80.7346, 7.5101],
               [-80.7652, 7.5412],
               [-80.8086, 7.5487],
               [-80.8303, 7.5722],
               [-80.8995, 7.6866],
               [-80.9258, 7.7608],
               [-80.9587, 7.7607],
               [-80.9304, 7.8736],
               [-80.9027, 7.9268],
               [-80.8706, 7.9232],
               [-80.8428, 7.9634],
               [-80.8213, 8.017],
               [-80.8365, 8.0659],
               [-80.7941, 8.0623],
               [-80.7537, 8.0744],
               [-80.7198, 8.121],
               [-80.6739, 8.1178],
            ],
         ],
      },
      geometryType: "Polygon",
      name: "Herrera",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-7",
      value: 0,
      geometry: {
         type: "Polygon",
         coordinates: [
            [
               [-80.3662, 8.004],
               [-80.3262, 7.9393],
               [-80.3174, 7.9018],
               [-80.286, 7.8651],
               [-80.2251, 7.8279],
               [-80.1899, 7.7818],
               [-80.1324, 7.7349],
               [-80.1215, 7.7101],
               [-80.0554, 7.6454],
               [-80.0282, 7.5685],
               [-79.9912, 7.5146],
               [-79.9979, 7.469],
               [-80.0554, 7.4532],
               [-80.0954, 7.4285],
               [-80.1315, 7.4301],
               [-80.164, 7.4121],
               [-80.1952, 7.4329],
               [-80.2396, 7.4238],
               [-80.2763, 7.4346],
               [-80.3646, 7.3654],
               [-80.3418, 7.3199],
               [-80.3921, 7.3051],
               [-80.4307, 7.2438],
               [-80.5076, 7.251],
               [-80.6147, 7.2301],
               [-80.6189, 7.2816],
               [-80.6719, 7.3197],
               [-80.6714, 7.3548],
               [-80.7176, 7.3916],
               [-80.7084, 7.4128],
               [-80.7343, 7.453],
               [-80.7346, 7.5101],
               [-80.6742, 7.5772],
               [-80.6285, 7.6513],
               [-80.5858, 7.6612],
               [-80.5635, 7.7114],
               [-80.6002, 7.8021],
               [-80.541, 7.8397],
               [-80.5227, 7.8946],
               [-80.4757, 7.937],
               [-80.4464, 7.9303],
               [-80.3838, 7.9718],
               [-80.3662, 8.004],
            ],
         ],
      },
      geometryType: "Polygon",
      name: "Los Santos",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-8",
      value: 0,
      geometry: {
         type: "MultiPolygon",
         coordinates: [
            [
               [
                  [-79.1032, 8.3174],
                  [-79.0829, 8.2871],
                  [-79.1204, 8.2082],
                  [-79.1426, 8.2438],
                  [-79.1299, 8.2954],
                  [-79.1032, 8.3174],
               ],
            ],
            [
               [
                  [-78.9129, 8.471],
                  [-78.8615, 8.4502],
                  [-78.8543, 8.3799],
                  [-78.8388, 8.3404],
                  [-78.8562, 8.2954],
                  [-78.904, 8.3071],
                  [-78.9351, 8.2768],
                  [-78.9629, 8.2915],
                  [-78.9543, 8.3476],
                  [-78.9729, 8.4562],
                  [-78.9129, 8.471],
               ],
            ],
            [
               [
                  [-79.6901, 9.1079],
                  [-79.6476, 9.2085],
                  [-79.589, 9.221],
                  [-79.5935, 9.2821],
                  [-79.5563, 9.3468],
                  [-79.5669, 9.3769],
                  [-79.5423, 9.4226],
                  [-79.5315, 9.4813],
                  [-79.4827, 9.4887],
                  [-79.4527, 9.5233],
                  [-79.3774, 9.4726],
                  [-79.3809, 9.444],
                  [-79.3492, 9.4299],
                  [-79.3059, 9.4541],
                  [-79.2615, 9.4245],
                  [-79.2511, 9.3447],
                  [-79.2393, 9.3077],
                  [-79.1765, 9.3386],
                  [-79.1059, 9.3347],
                  [-79.0991, 9.3053],
                  [-79.0266, 9.295],
                  [-78.9693, 9.312],
                  [-78.9255, 9.3096],
                  [-78.891, 9.3677],
                  [-78.863, 9.3543],
                  [-78.8162, 9.3836],
                  [-78.7431, 9.3621],
                  [-78.7149, 9.3841],
                  [-78.6216, 9.3198],
                  [-78.5468, 9.3271],
                  [-78.5036, 9.3475],
                  [-78.5008, 9.3127],
                  [-78.4767, 9.3],
                  [-78.4484, 9.2459],
                  [-78.4139, 9.2374],
                  [-78.4071, 9.2662],
                  [-78.3404, 9.2449],
                  [-78.3034, 9.2633],
                  [-78.2922, 9.2115],
                  [-78.2442, 9.1849],
                  [-78.2171, 9.1961],
                  [-78.1262, 9.1799],
                  [-78.0831, 9.1611],
                  [-78.0695, 9.1003],
                  [-78.0488, 9.0895],
                  [-78.0611, 9.0384],
                  [-78.0845, 9.0301],
                  [-78.1127, 8.9756],
                  [-78.1096, 8.9449],
                  [-78.1532, 8.9248],
                  [-78.1752, 8.861],
                  [-78.2402, 8.8761],
                  [-78.3026, 8.8155],
                  [-78.3334, 8.7956],
                  [-78.3618, 8.8321],
                  [-78.404, 8.8376],
                  [-78.4491, 8.8274],
                  [-78.4828, 8.8049],
                  [-78.4814, 8.7504],
                  [-78.5122, 8.7013],
                  [-78.4728, 8.6425],
                  [-78.49, 8.6005],
                  [-78.4771, 8.5343],
                  [-78.4417, 8.4714],
                  [-78.4223, 8.3997],
                  [-78.4208, 8.3474],
                  [-78.4693, 8.4371],
                  [-78.4968, 8.4471],
                  [-78.4957, 8.5204],
                  [-78.534, 8.5729],
                  [-78.5279, 8.6376],
                  [-78.6185, 8.6721],
                  [-78.641, 8.7065],
                  [-78.6643, 8.7063],
                  [-78.7043, 8.7499],
                  [-78.7529, 8.774],
                  [-78.7604, 8.8121],
                  [-78.7996, 8.7993],
                  [-78.8646, 8.8332],
                  [-78.8846, 8.8935],
                  [-78.9107, 8.9251],
                  [-78.9638, 8.9401],
                  [-79.0368, 8.979],
                  [-79.1774, 9.0151],
                  [-79.2901, 9.024],
                  [-79.3479, 9.0229],
                  [-79.4743, 9.0082],
                  [-79.5465, 8.9482],
                  [-79.5679, 8.9474],
                  [-79.5851, 8.9911],
                  [-79.6739, 9.0692],
                  [-79.6917, 9.1063],
                  [-79.6901, 9.1079],
               ],
            ],
         ],
      },
      geometryType: "MultiPolygon",
      name: "Panamá",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-9",
      value: 0,
      geometry: {
         type: "MultiPolygon",
         coordinates: [
            [
               [
                  [-81.794, 7.3846],
                  [-81.8643, 7.4463],
                  [-81.8735, 7.4887],
                  [-81.8268, 7.5921],
                  [-81.8029, 7.5965],
                  [-81.7646, 7.6432],
                  [-81.7301, 7.6318],
                  [-81.724, 7.574],
                  [-81.6818, 7.5026],
                  [-81.7129, 7.4943],
                  [-81.7262, 7.4385],
                  [-81.6876, 7.3874],
                  [-81.6326, 7.3954],
                  [-81.604, 7.354],
                  [-81.644, 7.3165],
                  [-81.6929, 7.3399],
                  [-81.7604, 7.3513],
                  [-81.794, 7.3846],
               ],
            ],
            [
               [
                  [-81.096, 7.5826],
                  [-81.0435, 7.5729],
                  [-81.0821, 7.5279],
                  [-81.1226, 7.5479],
                  [-81.191, 7.491],
                  [-81.2204, 7.486],
                  [-81.2318, 7.5182],
                  [-81.1718, 7.5326],
                  [-81.1504, 7.5618],
                  [-81.096, 7.5826],
               ],
            ],
            [
               [
                  [-80.874, 8.8823],
                  [-80.861, 8.8355],
                  [-80.7993, 8.8088],
                  [-80.7728, 8.8143],
                  [-80.726, 8.7486],
                  [-80.7548, 8.7271],
                  [-80.7242, 8.66],
                  [-80.7434, 8.6393],
                  [-80.746, 8.6059],
                  [-80.7819, 8.5496],
                  [-80.7876, 8.5159],
                  [-80.7744, 8.4752],
                  [-80.7448, 8.4507],
                  [-80.7359, 8.4126],
                  [-80.7129, 8.3877],
                  [-80.6966, 8.3327],
                  [-80.6612, 8.3196],
                  [-80.6891, 8.2573],
                  [-80.6843, 8.24],
                  [-80.7095, 8.1662],
                  [-80.6739, 8.1178],
                  [-80.7198, 8.121],
                  [-80.7537, 8.0744],
                  [-80.7941, 8.0623],
                  [-80.8365, 8.0659],
                  [-80.8213, 8.017],
                  [-80.8428, 7.9634],
                  [-80.8706, 7.9232],
                  [-80.9027, 7.9268],
                  [-80.9304, 7.8736],
                  [-80.9587, 7.7607],
                  [-80.9258, 7.7608],
                  [-80.8995, 7.6866],
                  [-80.8303, 7.5722],
                  [-80.8086, 7.5487],
                  [-80.7652, 7.5412],
                  [-80.7346, 7.5101],
                  [-80.7343, 7.453],
                  [-80.7084, 7.4128],
                  [-80.7176, 7.3916],
                  [-80.6714, 7.3548],
                  [-80.6719, 7.3197],
                  [-80.6189, 7.2816],
                  [-80.6147, 7.2301],
                  [-80.6365, 7.2312],
                  [-80.7129, 7.2065],
                  [-80.7785, 7.2168],
                  [-80.8032, 7.2065],
                  [-80.8882, 7.2043],
                  [-80.9215, 7.2782],
                  [-80.9165, 7.3221],
                  [-80.894, 7.3496],
                  [-80.9324, 7.4232],
                  [-80.916, 7.4418],
                  [-80.9593, 7.5162],
                  [-80.9557, 7.5629],
                  [-80.9743, 7.6012],
                  [-81.0521, 7.6757],
                  [-81.0312, 7.6954],
                  [-81.0557, 7.7507],
                  [-81.089, 7.7715],
                  [-81.0624, 7.8251],
                  [-81.0765, 7.8735],
                  [-81.1096, 7.8426],
                  [-81.1765, 7.8604],
                  [-81.1837, 7.8254],
                  [-81.1682, 7.7776],
                  [-81.2093, 7.669],
                  [-81.1974, 7.6524],
                  [-81.229, 7.6076],
                  [-81.2782, 7.6326],
                  [-81.2976, 7.6599],
                  [-81.3393, 7.6596],
                  [-81.4282, 7.6799],
                  [-81.4646, 7.7087],
                  [-81.5079, 7.7049],
                  [-81.5346, 7.7246],
                  [-81.5018, 7.7582],
                  [-81.5435, 7.7671],
                  [-81.5896, 7.7962],
                  [-81.5796, 7.871],
                  [-81.6118, 7.871],
                  [-81.6235, 7.9387],
                  [-81.6735, 8.0071],
                  [-81.6314, 8.0379],
                  [-81.6118, 8.1586],
                  [-81.5805, 8.1864],
                  [-81.5685, 8.1551],
                  [-81.5283, 8.1459],
                  [-81.4987, 8.1591],
                  [-81.5684, 8.1963],
                  [-81.5573, 8.2335],
                  [-81.5295, 8.2638],
                  [-81.5117, 8.2787],
                  [-81.5284, 8.3138],
                  [-81.5139, 8.3565],
                  [-81.5363, 8.3856],
                  [-81.5224, 8.4166],
                  [-81.4736, 8.4099],
                  [-81.425, 8.4365],
                  [-81.3982, 8.4967],
                  [-81.3017, 8.5306],
                  [-81.2849, 8.5067],
                  [-81.3019, 8.4763],
                  [-81.194, 8.4552],
                  [-81.1721, 8.4722],
                  [-81.1708, 8.5209],
                  [-81.2018, 8.5315],
                  [-81.2351, 8.5981],
                  [-81.2347, 8.6508],
                  [-81.2175, 8.7049],
                  [-81.2277, 8.7542],
                  [-81.2138, 8.7854],
                  [-81.114, 8.809],
                  [-81.0685, 8.8115],
                  [-80.9935, 8.8351],
                  [-80.9415, 8.8668],
                  [-80.874, 8.8823],
               ],
               [
                  [-81.4374, 8.2734],
                  [-81.4718, 8.3204],
                  [-81.4924, 8.2752],
                  [-81.4614, 8.2424],
                  [-81.4374, 8.2734],
               ],
               [
                  [-81.3917, 8.389],
                  [-81.4145, 8.3487],
                  [-81.3797, 8.3285],
                  [-81.3347, 8.3575],
                  [-81.3536, 8.3876],
                  [-81.3917, 8.389],
               ],
            ],
         ],
      },
      geometryType: "MultiPolygon",
      name: "Veraguas",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-10",
      value: 0,
      geometry: {
         type: "Polygon",
         coordinates: [
            [
               [-79.6917, 9.1063],
               [-79.6739, 9.0692],
               [-79.5851, 8.9911],
               [-79.5593, 8.9229],
               [-79.5663, 8.9001],
               [-79.6704, 8.8835],
               [-79.7404, 8.849],
               [-79.7721, 8.7782],
               [-79.7432, 8.7265],
               [-79.7685, 8.6771],
               [-79.8349, 8.6426],
               [-79.7865, 8.6137],
               [-79.784, 8.5765],
               [-79.8637, 8.5546],
               [-79.8751, 8.5318],
               [-79.9432, 8.4929],
               [-79.9582, 8.4626],
               [-80.0597, 8.4012],
               [-80.0981, 8.4709],
               [-80.0922, 8.5152],
               [-80.111, 8.5417],
               [-80.1002, 8.5871],
               [-80.1184, 8.6429],
               [-80.1109, 8.6757],
               [-80.1264, 8.7461],
               [-80.1559, 8.7812],
               [-80.169, 8.8903],
               [-80.1368, 8.9347],
               [-80.1362, 8.9658],
               [-80.0922, 9.0032],
               [-80.0513, 8.9764],
               [-79.9915, 8.971],
               [-79.9645, 8.984],
               [-79.9525, 9.0376],
               [-79.9798, 9.0957],
               [-79.9496, 9.1198],
               [-79.9478, 9.1589],
               [-79.9237, 9.1847],
               [-79.8448, 9.1848],
               [-79.8127, 9.1682],
               [-79.811, 9.1327],
               [-79.7644, 9.1072],
               [-79.744, 9.1196],
               [-79.6917, 9.1062],
               [-79.6917, 9.1063],
            ],
         ],
      },
      geometryType: "Polygon",
      name: "Panamá Oeste",
      CNTRY: "Panama",
      TYPE: "Province",
   },
   {
      id: "PA-EM",
      value: 0,
      geometry: {
         type: "MultiPolygon",
         coordinates: [
            [
               [
                  [-78.3357, 7.9024],
                  [-78.3278, 7.9249],
                  [-78.3262, 7.9638],
                  [-78.2968, 7.9653],
                  [-78.2574, 7.9961],
                  [-78.2398, 7.9866],
                  [-78.2262, 7.9831],
                  [-78.2027, 8.0066],
                  [-78.2337, 8.0657],
                  [-78.1885, 8.0943],
                  [-78.1186, 8.0177],
                  [-78.1075, 7.9779],
                  [-78.0866, 7.9655],
                  [-78.0772, 7.9179],
                  [-78.0333, 7.9518],
                  [-78.0063, 7.9035],
                  [-77.9764, 7.7951],
                  [-77.9479, 7.7604],
                  [-77.9568, 7.7354],
                  [-77.9439, 7.6774],
                  [-77.9448, 7.6233],
                  [-77.9881, 7.5908],
                  [-78.0077, 7.6192],
                  [-78.0415, 7.6082],
                  [-78.0632, 7.5812],
                  [-78.0944, 7.6482],
                  [-78.1459, 7.6793],
                  [-78.1817, 7.6646],
                  [-78.2335, 7.7151],
                  [-78.2685, 7.7696],
                  [-78.2759, 7.8062],
                  [-78.3169, 7.8591],
                  [-78.3357, 7.9024],
               ],
            ],
            [
               [
                  [-77.2449, 8.1744],
                  [-77.4206, 8.1343],
                  [-77.4695, 8.0951],
                  [-77.4993, 8.0989],
                  [-77.5401, 8.0779],
                  [-77.5878, 8.1232],
                  [-77.6237, 8.2009],
                  [-77.6605, 8.2293],
                  [-77.7292, 8.253],
                  [-77.7656, 8.3228],
                  [-77.7951, 8.35],
                  [-77.8158, 8.463],
                  [-77.8809, 8.5188],
                  [-77.8647, 8.5396],
                  [-77.8954, 8.5706],
                  [-77.9079, 8.628],
                  [-77.8691, 8.6585],
                  [-77.8331, 8.7272],
                  [-77.7845, 8.7417],
                  [-77.7581, 8.7702],
                  [-77.7059, 8.7679],
                  [-77.6277, 8.733],
                  [-77.6115, 8.6942],
                  [-77.5718, 8.6796],
                  [-77.581, 8.6401],
                  [-77.5542, 8.5999],
                  [-77.5045, 8.5961],
                  [-77.4816, 8.5425],
                  [-77.4615, 8.5237],
                  [-77.4427, 8.4774],
                  [-77.4031, 8.4762],
                  [-77.3627, 8.4238],
                  [-77.333, 8.3669],
                  [-77.3423, 8.3338],
                  [-77.3208, 8.2732],
                  [-77.294, 8.2266],
                  [-77.2547, 8.2066],
                  [-77.2449, 8.1744],
               ],
            ],
         ],
      },
      geometryType: "MultiPolygon",
      name: "Emberá",
      CNTRY: "Panama",
      TYPE: "Indigenous",
   },
   {
      id: "PA-KY",
      value: 0,
      geometry: {
         type: "Polygon",
         coordinates: [
            [
               [-77.4615, 8.5237],
               [-77.4816, 8.5425],
               [-77.5045, 8.5961],
               [-77.5542, 8.5999],
               [-77.581, 8.6401],
               [-77.5718, 8.6796],
               [-77.6115, 8.6942],
               [-77.6277, 8.733],
               [-77.7059, 8.7679],
               [-77.699, 8.8139],
               [-77.7348, 8.8344],
               [-77.7554, 8.8703],
               [-77.8348, 8.9075],
               [-77.9132, 8.9999],
               [-77.9155, 9.0206],
               [-77.9576, 9.0409],
               [-77.9863, 9.0723],
               [-78.0488, 9.0895],
               [-78.0695, 9.1003],
               [-78.0831, 9.1611],
               [-78.1262, 9.1799],
               [-78.2171, 9.1961],
               [-78.2442, 9.1849],
               [-78.2922, 9.2115],
               [-78.3034, 9.2633],
               [-78.3404, 9.2449],
               [-78.4071, 9.2662],
               [-78.4139, 9.2374],
               [-78.4484, 9.2459],
               [-78.4767, 9.3],
               [-78.5008, 9.3127],
               [-78.5036, 9.3475],
               [-78.5468, 9.3271],
               [-78.6216, 9.3198],
               [-78.7149, 9.3841],
               [-78.7431, 9.3621],
               [-78.8162, 9.3836],
               [-78.863, 9.3543],
               [-78.891, 9.3677],
               [-78.9255, 9.3096],
               [-78.9693, 9.312],
               [-79.0266, 9.295],
               [-79.0991, 9.3053],
               [-79.1059, 9.3347],
               [-79.1765, 9.3386],
               [-79.2393, 9.3077],
               [-79.2511, 9.3447],
               [-79.1979, 9.3875],
               [-79.1713, 9.393],
               [-79.1403, 9.4597],
               [-79.0889, 9.4819],
               [-79.0803, 9.5582],
               [-78.9715, 9.5671],
               [-78.9985, 9.5374],
               [-79.0493, 9.536],
               [-79.0746, 9.4801],
               [-79.0526, 9.4385],
               [-78.9604, 9.4593],
               [-78.9129, 9.4268],
               [-78.859, 9.4229],
               [-78.7271, 9.4582],
               [-78.7157, 9.4426],
               [-78.5965, 9.4221],
               [-78.5763, 9.4437],
               [-78.5165, 9.4182],
               [-78.4326, 9.4049],
               [-78.4235, 9.3862],
               [-78.3079, 9.3354],
               [-78.2613, 9.321],
               [-78.2138, 9.2913],
               [-78.1762, 9.2926],
               [-78.1082, 9.2555],
               [-78.0515, 9.2485],
               [-78.0035, 9.1954],
               [-77.9485, 9.1618],
               [-77.934, 9.1279],
               [-77.874, 9.0818],
               [-77.8465, 9.081],
               [-77.8096, 9.0546],
               [-77.8085, 9.0313],
               [-77.7535, 8.996],
               [-77.7621, 8.9579],
               [-77.6465, 8.8279],
               [-77.6215, 8.8374],
               [-77.541, 8.7699],
               [-77.5468, 8.7126],
               [-77.4807, 8.6746],
               [-77.429, 8.6615],
               [-77.3949, 8.6768],
               [-77.3961, 8.6149],
               [-77.4421, 8.5685],
               [-77.4615, 8.5237],
            ],
         ],
      },
      geometryType: "Polygon",
      name: "Guna Yala",
      CNTRY: "Panama",
      TYPE: "Indigenous",
   },
   {
      id: "PA-NB",
      value: 0,
      geometry: {
         type: "MultiPolygon",
         coordinates: [
            [
               [
                  [-81.2138, 8.7854],
                  [-81.2277, 8.7542],
                  [-81.2175, 8.7049],
                  [-81.2347, 8.6508],
                  [-81.2351, 8.5981],
                  [-81.2018, 8.5315],
                  [-81.1708, 8.5209],
                  [-81.1721, 8.4722],
                  [-81.194, 8.4552],
                  [-81.3019, 8.4763],
                  [-81.2849, 8.5067],
                  [-81.3017, 8.5306],
                  [-81.3982, 8.4967],
                  [-81.425, 8.4365],
                  [-81.4736, 8.4099],
                  [-81.5224, 8.4166],
                  [-81.5363, 8.3856],
                  [-81.5709, 8.3653],
                  [-81.6125, 8.3546],
                  [-81.6291, 8.3051],
                  [-81.6649, 8.273],
                  [-81.7061, 8.2946],
                  [-81.7492, 8.2745],
                  [-81.8035, 8.2718],
                  [-81.8316, 8.297],
                  [-81.8279, 8.3308],
                  [-81.9748, 8.3191],
                  [-82.0199, 8.3612],
                  [-82.021, 8.4234],
                  [-82.0524, 8.4241],
                  [-82.058, 8.4537],
                  [-82.0924, 8.459],
                  [-82.1729, 8.4427],
                  [-82.1776, 8.4834],
                  [-82.145, 8.5439],
                  [-82.1614, 8.5689],
                  [-82.1466, 8.6006],
                  [-82.1471, 8.7604],
                  [-82.2608, 8.7664],
                  [-82.3005, 8.8147],
                  [-82.3732, 8.831],
                  [-82.3573, 8.8283],
                  [-82.3413, 8.893],
                  [-82.3453, 8.9505],
                  [-82.3661, 8.97],
                  [-82.4258, 8.9847],
                  [-82.4427, 9.0023],
                  [-82.3837, 9.026],
                  [-82.3234, 9.1241],
                  [-82.2821, 9.0636],
                  [-82.3264, 9.0348],
                  [-82.3258, 9.0097],
                  [-82.2755, 8.9996],
                  [-82.1945, 8.9495],
                  [-82.1977, 8.9163],
                  [-82.1807, 8.8486],
                  [-82.1583, 8.8887],
                  [-82.084, 8.9277],
                  [-82.0772, 8.9232],
                  [-82.053, 8.8951],
                  [-82.0178, 8.9216],
                  [-82.0005, 8.9571],
                  [-81.9812, 8.941],
                  [-81.9371, 8.9449],
                  [-81.9243, 8.9888],
                  [-81.8918, 8.9601],
                  [-81.8576, 8.9671],
                  [-81.8029, 8.936],
                  [-81.7696, 8.9796],
                  [-81.7826, 9.0082],
                  [-81.8279, 9.0226],
                  [-81.8649, 9.0596],
                  [-81.8765, 9.0896],
                  [-81.9476, 9.1371],
                  [-81.9221, 9.1504],
                  [-81.9185, 9.1851],
                  [-81.8815, 9.1843],
                  [-81.8538, 9.1435],
                  [-81.824, 9.0707],
                  [-81.7607, 9.0632],
                  [-81.7176, 9.0301],
                  [-81.6937, 8.9854],
                  [-81.5862, 8.8615],
                  [-81.5343, 8.8182],
                  [-81.4971, 8.7996],
                  [-81.4771, 8.8093],
                  [-81.411, 8.8037],
                  [-81.3512, 8.7829],
                  [-81.296, 8.7818],
                  [-81.2343, 8.7962],
                  [-81.2138, 8.7854],
               ],
            ],
            [
               [
                  [-81.3917, 8.389],
                  [-81.3536, 8.3876],
                  [-81.3347, 8.3575],
                  [-81.3797, 8.3285],
                  [-81.4145, 8.3487],
                  [-81.3917, 8.389],
               ],
            ],
            [
               [
                  [-81.4374, 8.2734],
                  [-81.4614, 8.2424],
                  [-81.4924, 8.2752],
                  [-81.4718, 8.3204],
                  [-81.4374, 8.2734],
               ],
            ],
            [
               [
                  [-81.5684, 8.1963],
                  [-81.4987, 8.1591],
                  [-81.5283, 8.1459],
                  [-81.5685, 8.1551],
                  [-81.5805, 8.1864],
                  [-81.5684, 8.1963],
               ],
            ],
            [
               [
                  [-81.5295, 8.2638],
                  [-81.597, 8.2993],
                  [-81.6094, 8.3305],
                  [-81.5709, 8.3653],
                  [-81.5351, 8.3403],
                  [-81.5284, 8.3138],
                  [-81.5117, 8.2787],
                  [-81.5295, 8.2638],
               ],
            ],
         ],
      },
      geometryType: "MultiPolygon",
      name: "Ngöbe-Buglé",
      CNTRY: "Panama",
      TYPE: "Indigenous",
   },
];
