export const ACCESS_AGENTS_MIN = 1;
export const ACCESS_COMPANIES_MIN = 1;
export const ACCESS_ADMINS_MIN = 1;
export const ACCESS_LINKS_MIN = 2;
export const ACCESS_LISTINGS_MIN = 8;
export const ACCESS_OFFICES_MIN = 2;
export const ACCESS_IMAGES_MIN = 2;
export const ACCESS_DATA_MIN = 1;
export const ACCESS_CHARTS_MIN = 8;
export const ACCESS_MAPS_MIN = 8;

//agent.accessLevel <= ACCESS_IMAGES_MIN
