//import { DashboardTemplate } from "./Template/DashboardTemplate";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { useEffect, useRef, useState } from "react";

import LoadBox from "./LoadBox";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { fetchData, fetchDataAnd, msg } from "../../../utilities/gen";

import { AgentsState } from "../../../features/agents/agents";
import { clearAgents, setAgents } from "../../../features/agents/agentsSlice";
import {
   clearCompanies,
   setCompanies,
} from "../../../features/companies/companiesSlice";
import { clearLinks, setLinks } from "../../../features/links/linksSlice";
import {
   setListings,
   clearListings,
} from "../../../features/listings/listingsSlice";
import {
   clearOffices,
   setOffices,
} from "../../../features/offices/officesSlice";
import { CompaniesState } from "../../../features/companies/companies";
import { SessionState } from "../../../features/session/session";
import { clearImages, setImages } from "../../../features/images/imageSlice";
import {
   ACCESS_AGENTS_MIN,
   ACCESS_COMPANIES_MIN,
   ACCESS_IMAGES_MIN,
   ACCESS_LINKS_MIN,
   ACCESS_LISTINGS_MIN,
   ACCESS_OFFICES_MIN,
   ACCESS_ADMINS_MIN,
} from "../../../constants/access";
import { AdminsState } from "../../../features/admins/admins";
import { setAdmins } from "../../../features/admins/adminsSlice";

const Success = () => {
   return (
      <>
         <CheckCircleIcon color='success' />
      </>
   );
};

const Loading = () => {
   return (
      <>
         <HourglassTopIcon color='info' /> Loading from Database
      </>
   );
};

export const Dashboard = () => {
   const dispatch = useAppDispatch();

   const agents: AgentsState = useAppSelector((state) => state.agents);
   const companies: CompaniesState = useAppSelector((state) => state.companies);
   const links: any = useAppSelector((state) => state.links);
   const listings: any = useAppSelector((state) => state.listings);
   const offices: any = useAppSelector((state) => state.offices);
   const session: SessionState = useAppSelector((state) => state.session);
   const admins: AdminsState = useAppSelector((state) => state.admins);
   //const images: any = useAppSelector((state) => state.images);
   const token = session.user.token;
   const agent = session.agent;

   if (agent.accessLevel > ACCESS_AGENTS_MIN)
      dispatch(setAgents({ ...agents, init: true }));
   if (agent.accessLevel > ACCESS_COMPANIES_MIN)
      dispatch(setCompanies({ ...companies, init: true }));
   if (agent.accessLevel > ACCESS_LINKS_MIN)
      dispatch(setLinks({ ...links, init: true }));
   if (agent.accessLevel > ACCESS_LISTINGS_MIN)
      dispatch(setListings({ ...listings, init: true }));
   if (agent.accessLevel > ACCESS_OFFICES_MIN)
      dispatch(setOffices({ ...offices, init: true }));
   if (agent.accessLevel > ACCESS_ADMINS_MIN)
      dispatch(setAdmins({ ...admins, init: true }));

   const init = useRef<boolean>(false);
   const initAgents = useRef<boolean>(false);
   const initCompanies = useRef<boolean>(false);
   const initLinks = useRef<boolean>(false);
   const initListings = useRef<boolean>(false);
   const initOffices = useRef<boolean>(false);
   const initImages = useRef<boolean>(false);
   const initAdmins = useRef<boolean>(false);

   const [agentsMsg, agentsMsgSet] = useState<JSX.Element>(
      agents.init ? <Success /> : <Loading />
   );
   const [companiesMsg, companiesMsgSet] = useState<JSX.Element>(
      companies.init ? <Success /> : <Loading />
   );
   const [linksMsg, linksMsgSet] = useState<JSX.Element>(
      links.init ? <Success /> : <Loading />
   );
   const [listingsMsg, listingsMsgSet] = useState<JSX.Element>(
      listings.init ? <Success /> : <Loading />
   );
   const [officesMsg, officesMsgSet] = useState<JSX.Element>(
      offices.init ? <Success /> : <Loading />
   );
   const [adminssMsg, adminssMsgSet] = useState<JSX.Element>(
      admins.init ? <Success /> : <Loading />
   );

   // const [imagesMsg, imagesMsgSet] = useState<JSX.Element>(
   //    images.init ? <Success /> : <Loading />
   // );

   if (!init.current) {
      init.current = true;
      (async () => {
         const entityData = {
            agents: {
               msgSetFn: agentsMsgSet,
               errorMsg: "Error Loading Agents",
            },
            companies: {
               msgSetFn: companiesMsgSet,
               errorMsg: "Error Loading Companies",
            },
            links: { msgSetFn: linksMsgSet, errorMsg: "Error Loading Links" },
            listings: {
               msgSetFn: listingsMsgSet,
               errorMsg: "Error Loading Listings",
            },
            offices: {
               msgSetFn: officesMsgSet,
               errorMsg: "Error Loading Offices",
            },
            admins: {
               msgSetFn: adminssMsgSet,
               errorMsg: "Error Loading Admins",
            },
            // images: {
            //    msgSetFn: imagesMsgSet,
            //    errorMsg: "Error Loading Images",
            // },
         };

         for (const [entity, { msgSetFn, errorMsg }] of Object.entries(
            entityData
         )) {
            try {
               // only fetch dat if the init flag is false
               if (
                  (agents.init === false &&
                     agent.accessLevel <= ACCESS_AGENTS_MIN) ||
                  (companies.init === false &&
                     agent.accessLevel <= ACCESS_COMPANIES_MIN) ||
                  (links.init === false &&
                     agent.accessLevel <= ACCESS_LINKS_MIN) ||
                  (listings.init === false &&
                     agent.accessLevel <= ACCESS_LISTINGS_MIN) ||
                  (offices.init === false &&
                     agent.accessLevel <= ACCESS_OFFICES_MIN) ||
                  (admins.init === false &&
                     agent.accessLevel <= ACCESS_ADMINS_MIN)
               ) {
                  switch (entity) {
                     case "agents": {
                        if (
                           agent.accessLevel <= ACCESS_AGENTS_MIN &&
                           !initAgents.current
                        ) {
                           initAgents.current = true;
                           const data = await fetchData(entity, token);
                           dispatch(setAgents({ arr: data, init: true }));
                        }

                        break;
                     }
                     case "companies": {
                        if (
                           agent.accessLevel <= ACCESS_COMPANIES_MIN &&
                           !initCompanies.current
                        ) {
                           initCompanies.current = true;
                           const data = await fetchData(entity, token);
                           dispatch(setCompanies({ arr: data, init: true }));
                        }
                        break;
                     }
                     case "links": {
                        if (
                           agent.accessLevel <= ACCESS_LINKS_MIN &&
                           !initLinks.current
                        ) {
                           initLinks.current = true;
                           const data = await fetchData(entity, token);
                           dispatch(setLinks({ arr: data, init: true }));
                        }
                        break;
                     }
                     case "listings": {
                        if (
                           agent.accessLevel <= ACCESS_LISTINGS_MIN &&
                           !initListings.current
                        ) {
                           {
                              initListings.current = true;
                              const data = await fetchData(entity, token);
                              // sort array of objects in Desceding order on id field
                              let tmp = data;
                              tmp.sort((a: any, b: any) => b.id - a.id);

                              dispatch(setListings({ arr: tmp, init: true }));
                           }
                        }
                        break;
                     }
                     case "offices": {
                        if (
                           agent.accessLevel <= ACCESS_OFFICES_MIN &&
                           !initOffices.current
                        ) {
                           initOffices.current = true;
                           const data = await fetchData(entity, token);
                           dispatch(setOffices({ arr: data, init: true }));
                        }
                        break;
                     }
                     case "admins": {
                        if (
                           agent.accessLevel <= ACCESS_ADMINS_MIN &&
                           !initAdmins.current
                        ) {
                           initAdmins.current = true;
                           const data = await fetchData(entity, token);
                           dispatch(setAdmins({ arr: data, init: true }));
                        }
                        break;
                     }
                     case "images": {
                        //if (agent.accessLevel <= ACCESS_IMAGES_MIN) {
                        // if (images.init === false && !initImages.current) {
                        //    initImages.current = true;
                        //    //const data = await fetchData(entity, token);
                        //    const data = await fetchDataAnd(
                        //       "images",
                        //       token,
                        //       "imageName",
                        //       "orphaned",
                        //       "0"
                        //    );
                        //    dispatch(setImages({ arr: data, init: true }));
                        // }
                        break;
                     }
                     default: {
                        console.log("no case - default");
                        break;
                     }
                  }
               }
               //dispatch(setAgents({ arr: data, init: true }));
               msgSetFn(<Success />);
               dispatch(addSnackbarMsg(msg(`${entity} Loaded`, "success")));
            } catch (error) {
               console.log("-----error----");
               console.log(error);
               dispatch(addSnackbarMsg(msg(errorMsg, "error")));
            }
         }
      })();
   }

   return (
      <DashboardTemplate>
         <h3>Dashboard Home</h3>
         {agent.accessLevel <= ACCESS_AGENTS_MIN && (
            <LoadBox
               objArr={agents.arr}
               msg={agentsMsg}
               init={agents.init}
               tableName='agents'
            />
         )}

         {agent.accessLevel <= ACCESS_COMPANIES_MIN && (
            <LoadBox
               objArr={companies.arr}
               msg={companiesMsg}
               init={companies.init}
               tableName='companies'
            />
         )}

         {agent.accessLevel <= ACCESS_LINKS_MIN && (
            <LoadBox
               objArr={links.arr}
               msg={linksMsg}
               init={links.init}
               tableName='links'
            />
         )}

         {agent.accessLevel <= ACCESS_LISTINGS_MIN && (
            <LoadBox
               objArr={listings.arr}
               msg={listingsMsg}
               init={listings.init}
               tableName='listings'
            />
         )}

         {agent.accessLevel <= ACCESS_ADMINS_MIN && (
            <LoadBox
               objArr={offices.arr}
               msg={officesMsg}
               init={offices.init}
               tableName='offices'
            />
         )}

         {agent.accessLevel <= ACCESS_ADMINS_MIN && (
            <LoadBox
               objArr={admins.arr}
               msg={adminssMsg}
               init={admins.init}
               tableName='admins'
            />
         )}

         {/* {agent.accessLevel <= ACCESS_IMAGES_MIN && (
            <LoadBox
               objArr={images.arr}
               msg={imagesMsg}
               init={images.init}
               tableName='images'
            />
         )} */}
         {/* <CsvControl /> */}
      </DashboardTemplate>
   );
};
